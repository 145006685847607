import { NotificationContainer } from '@components/notification';
import VerticalNavbarNew from '@root/components/falconReact/navbar/VerticalNavbarNew';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
// import Footer from '../components/common/Footer';
import TopNavbarNew from '../components/falconReact/navbar/TopNavbarNew';
import './MainLayout.scss';
import usePubSub from '@root/hooks/usePubSub';

const MainLayout = ({ children }) => {
  let currentLocation = useLocation();
  let currentPathname = currentLocation.pathname;
  const sidebarWidth = useSelector(state => state.common.sidebarWidth);
  const [refreshKey, setRefreshKey] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPathname]);

  usePubSub(
    'refresh_page',
    useCallback(data => {
      setRefreshKey(data);
    }, []),
  );

  const _content = useMemo(() => {
    return (
      <div key={refreshKey} className="main-container-box__inner">
        <NotificationContainer />
        {children}
      </div>
    );
  }, [children, refreshKey]);

  return (
    <div className="container-fluid p-0 MainLayout__Container">
      {/* <NavbarVertical navbarStyle={navbarStyle} isDisabled={isMenuLocked} /> */}
      <VerticalNavbarNew />
      <div style={{ flexGrow: '1', maxWidth: `calc(100vw - ${sidebarWidth}px)` }}>
        <div className="content_inner">
          {/* <NavbarTop>{topNav}</NavbarTop> */}
          <TopNavbarNew />
          <div className="main-container-box CustomScrollbar CustomScrollbar-Large">{_content}</div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default MainLayout;
