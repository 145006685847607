import React, { useMemo } from 'react';
import Text from '@root/components/v2/ui-elements/Text';
import { Box } from '@mui/material';
import './style.scss';
import TopOpenTooltip from '../../ui-elements/Tooltip/TopOpenTooltip';

// const TITLE_SIZE = {
//   small: 'fs__3',
//   normal: 'fs__4',
//   medium: 'text-medium',
//   fsmall: 'text-small',
//   smallest: 'text-smallest',
//   standard: 'text-standard',
// };

/**
 *
 * @param {*} param0
 * @param {*} param0.paddingMode container|content - When `content`, the content is expected to provide its own padding. When `container`, the component uses the default padding.
 * @returns
 */
function ContentBox({
  title,
  subtitle,
  containerStyle,
  titleContainerStyle,
  // titleSize = 'normal',
  paddingMode = 'container', // container|content
  headerHeight = undefined,
  headerEndElement,
  containerClassName,
  height,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  children,
  width,
  paddingTop,
  paddingBottom,
  minHeight,
  onClick,
  padding = '15px',
  overflowY = 'none',
  border,
  style,
  infoMsg,
  borderRadius,
  borderBottom,

  // boxHeight,
}) {
  const _containerStyle = useMemo(() => {
    return {
      ...style,
      ...(padding && { padding }),
      ...(height && { height }),
      ...(minHeight && { minHeight }),
      ...(paddingTop && { paddingTop }),
      ...(paddingBottom && { paddingBottom }),
      ...(marginBottom && { marginBottom }),
      ...(marginTop && { marginTop }),
      ...(marginLeft && { marginLeft }),
      ...(marginRight && { marginRight }),
      ...(width && { width }),
      ...(overflowY && { overflowY }),
      ...(border && { border }),
      ...(borderBottom && { borderBottom }),
      // width: `${width}`,
      // overflow: 'scroll',
      // height: `${boxHeight}`,
      ...(paddingMode === 'content' && { padding: 0 }),
      ...(containerStyle || {}),
    };
  }, [
    border,
    borderBottom,
    containerStyle,
    height,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    minHeight,
    overflowY,
    padding,
    paddingBottom,
    paddingMode,
    paddingTop,
    style,
    width,
  ]);

  const _titleStyle = useMemo(() => {
    return {
      ...((paddingMode === 'content' || headerHeight) && {
        height: headerHeight || '40px',
        paddingLeft: '15px',
        paddingRight: '15px',
        paddingBottom: 0,
        marginBottom: 0,
        // backgroundColor: '#F6F8FB',
      }),
      ...(titleContainerStyle || {}),
      ...{ borderRadius: borderRadius ?? '10px 10px 0px 0px' },
    };
  }, [borderRadius, headerHeight, paddingMode, titleContainerStyle]);

  const _headerEndStyle = useMemo(() => {
    return { ...(titleContainerStyle || {}) };
  }, [titleContainerStyle]);

  return (
    <div className={`ContentBox ${containerClassName || ''}`} style={_containerStyle} onClick={onClick}>
      {title || subtitle ? (
        <div className="ContentBox__TitleContainer" style={_titleStyle}>
          <div className="ContentBox__Title__Inner">
            <Box display={'flex'} alignItems={'center'} gap="5px">
              <Text
                element="h1"
                font="semi-bold"
                className={`standard SingleLine content-box-heading`}
                // style={{ lineHeight: '24.59px' }}
                title={typeof title === 'string' ? title : ''}>
                {title}
              </Text>
              {infoMsg && (
                <TopOpenTooltip
                  title={
                    <div style={{ padding: '15px' }}>
                      <span> {infoMsg}</span>
                    </div>
                  }>
                  <div style={{ width: '15px', height: '20px', display: 'flex', alignItems: 'center' }}>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6 11.2C8.87188 11.2 11.2 8.87188 11.2 6C11.2 3.12812 8.87188 0.8 6 0.8C3.12812 0.799999 0.800001 3.12812 0.800001 6C0.8 8.87188 3.12812 11.2 6 11.2ZM12 6C12 9.31371 9.31371 12 6 12C2.68629 12 2.34843e-07 9.31371 5.24537e-07 6C8.1423e-07 2.68629 2.68629 -8.1423e-07 6 -5.24537e-07C9.31371 -2.34843e-07 12 2.68629 12 6ZM6 9.62656C5.37909 9.62656 5.2 9.02091 5.2 8.8L5.2 5.44C5.2 5.21909 5.37909 4.82656 6 4.82656C6.62091 4.82656 6.8 5.21909 6.8 5.44L6.8 8.8C6.8 9.02091 6.62091 9.62656 6 9.62656Z"
                        fill="#718096"
                      />
                      <path
                        d="M5.2 3.62656C5.2 3.18473 5.55817 2.82656 6 2.82656C6.44183 2.82656 6.8 3.18473 6.8 3.62656C6.8 4.06839 6.44183 4.42656 6 4.42656C5.55817 4.42656 5.2 4.06839 5.2 3.62656Z"
                        fill="#718096"
                      />
                    </svg>
                  </div>
                </TopOpenTooltip>
              )}{' '}
            </Box>
            {subtitle ? (
              <Text element="h4" className="text-smallest" color="var(--contentbox-text-color-subtitle)">
                {subtitle}
              </Text>
            ) : null}
          </div>

          <Box flexGrow={1} />

          {headerEndElement}
        </div>
      ) : headerEndElement ? (
        <div className="" style={_headerEndStyle}>
          <div>{headerEndElement}</div>
        </div>
      ) : null}

      {children}
    </div>
  );
}

export default ContentBox;
