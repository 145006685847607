import { NOTIFICATION_BELL_DATA, NOTIFICATION_BELL_DATA_UPDATE, READ_ALL_NOTIFICATIONS } from '@constants/actionTypes';
import { axiosHelper } from '@root/services/axios';

export const fetchNotificationlistData = async (dispatch, page, onlyUnread, mark_all_read) => {
  const notificationList = await axiosHelper.axiosPost(
    'notification_bellicon_list',
    { data: { status: onlyUnread ? false : 'All', ...(mark_all_read && { mark_all_read: true }) } },
    false,
    page,
  );
  let tenantID = localStorage.getItem('tenantID');
  let notificationSocket = new WebSocket(`${import.meta.env.VITE_WEBSOCKET_NOTIFICATION}/${tenantID}`);
  // console.log(notificationSocket, 'notificationSocket');
  notificationSocket.onopen = params => {
    console.log('Scan history socket connected!', params);
  };
  notificationSocket.onmessage = params => {
    let responseData = JSON.parse(params['data']);
    dispatch({
      type: NOTIFICATION_BELL_DATA_UPDATE,
      payload: responseData,
    });
  };
  dispatch({
    type: NOTIFICATION_BELL_DATA,
    payload: notificationList,
  });
  if (mark_all_read)
    dispatch({
      type: READ_ALL_NOTIFICATIONS,
      payload: notificationList,
    });
  return notificationList;
};

export const logoutApi = async (dispatch, data) => {
  const logout = await axiosHelper.axiosPost('logout', { data }, false);
  return logout;
};
