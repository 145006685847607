import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
  useQueryClient,
  QueryFunction,
  QueryFunctionContext,
  hashQueryKey,
} from '@tanstack/react-query';
import { openDB, IDBPDatabase } from 'idb';
import { DatabaseConnection } from './DatabaseConnection';

interface CacheEntry<T> {
  data: T;
  timestamp: number;
}

const DB_NAME = 'ApiCache';
const STORE_NAME = 'responses';

// Open the IndexedDB database
async function openDatabase(): Promise<IDBPDatabase> {
  return openDB(DB_NAME, 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME);
      }
    },
  });
}

// Get data from cache if not expired

async function getFromCache<T>(key: string): Promise<T | null> {
  const startTime = Date.now();
  const db = await new DatabaseConnection().getConnection();
  console.log('Time taken', Date.now() - startTime);

  const tx = db.transaction(STORE_NAME, 'readonly');
  const store = tx.objectStore(STORE_NAME);
  const result = (await store.get(key)) as CacheEntry<T> | undefined;
  await tx.done;
  // Check if cache is within the 5-minute limit
  if (result && Date.now() - result.timestamp < 5 * 60 * 1000) {
    return result.data;
  }

  return null;
}

// Set data in the cache with timestamp
async function setCache<T>(key: string, data: T): Promise<void> {
  const db = await openDatabase();
  const tx = db.transaction(STORE_NAME, 'readwrite');
  const store = tx.objectStore(STORE_NAME);
  await store.put({ data, timestamp: Date.now() }, key);
  await tx.done;
}

// Clear all cached data from IndexedDB
async function clearCache(): Promise<void> {
  const db = await openDatabase();
  const tx = db.transaction(STORE_NAME, 'readwrite');
  const store = tx.objectStore(STORE_NAME);
  await store.clear(); // Clear all cached responses
  await tx.done;
}

// Custom hook for enhanced caching logic
function useEnhancedQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends [string, ...unknown[]] = [string],
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TData, TError> & { flushCache: () => Promise<void> } {
  const queryClient = useQueryClient();

  const enhancedQueryFn: QueryFunction<TQueryFnData, TQueryKey> = async (
    context: QueryFunctionContext<TQueryKey, any>,
  ) => {
    const { queryKey } = context;
    const cacheKey = hashQueryKey(queryKey);

    // Try to get data from cache
    const cachedData = await getFromCache<TQueryFnData>(cacheKey);

    if (cachedData) {
      // Background fetch to refresh the cache
      Promise.resolve().then(async () => {
        try {
          const freshData = await queryFn(context);
          if (JSON.stringify(freshData) !== JSON.stringify(cachedData)) {
            // If data has changed, update cache and queryClient data
            await setCache(cacheKey, freshData);
            queryClient.setQueryData(queryKey, freshData);
          }
        } catch (error) {
          console.error('Background fetch failed:', error);
        }
      });
      // console.log();
      // Return cached data immediately without setting loading state
      return cachedData;
    }

    // No cached data, fetch fresh data
    const freshData = await queryFn(context);
    // console.log(cachedData, freshData, 'freshData');
    await setCache(cacheKey, freshData);
    return freshData;
  };

  const result = useQuery<TQueryFnData, TError, TData, TQueryKey>(queryKey, enhancedQueryFn, {
    ...options,
    staleTime: Infinity, // Cache data is considered fresh
    cacheTime: Infinity,
    refetchOnMount: false, // Retain cache forever unless manually cleared
  });

  // Function to clear cache and invalidate queries
  const flushCache = async (): Promise<void> => {
    await clearCache();
    await queryClient.invalidateQueries(queryKey);
  };

  return { ...result, flushCache };
}

export default useEnhancedQuery;
