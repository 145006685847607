import { Box, Skeleton } from '@mui/material';

import CloudProviderBarSkeleton from '@root/components/v2/content/CloudProviderBar/skeleton';
import ContentBox from '@root/components/v2/content/ContentBox';
import PageContent from '@root/components/v2/layout/PageContent';
import PageHeaderSkeleton from '@root/components/v2/layout/PageHeader/Skeleton';
import Column from '@root/components/v2/layout/columns/Column';
import ColumnsContainer from '@root/components/v2/layout/columns/ColumnsContainer';

function CompliacneSkleton() {
  return (
    <PageContent>
      <PageHeaderSkeleton />
      <CloudProviderBarSkeleton />

      <ColumnsContainer marginBottom={'15px'}>
        <Column>
          <ContentBox
            containerClassName={'border-box'}
            paddingMode={'content'}
            title="Average Score"
            titleSize="medium"
            style={{
              height: '220px',
            }}
            infoMsg="Connected Environment(s) Score">
            <Box
              display={'flex'}
              height={'180px'}
              width={'100%'}
              justifyContent={'center'}
              alignItems={'center'}
              gap={'15px'}>
              <Skeleton variant="rectangular" height={'75px'} width={'120px'} sx={{ borderRadius: '8px' }} />
              <Skeleton variant="rectangular" height={'75px'} width={'120px'} sx={{ borderRadius: '8px' }} />
            </Box>
          </ContentBox>
        </Column>
        <Column>
          <ContentBox
            containerClassName={'border-box'}
            paddingMode={'content'}
            title="Non-Compliant Rules By Severity"
            titleSize="medium"
            height={'220px'}
            infoMsg="Breakdown of Severity">
            <Box height={'100%'} width={'100%'} padding={'15px'} display={'flex'} flexDirection={'column'} gap={'15px'}>
              <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
                  <Skeleton variant="rectangular" height={'40px'} width={'100px'} sx={{ borderRadius: '8px' }} />
                  <Skeleton variant="rectangular" height={'30px'} width={'100px'} sx={{ borderRadius: '8px' }} />
                </Box>
                <Box>
                  <Skeleton variant="rectangular" height={'50px'} width={'100px'} sx={{ borderRadius: '8px' }} />
                </Box>
              </Box>
              <Box display={'flex'} justifyContent={'space-evenly'}>
                {Array.from(new Array(4)).map((i, index) => (
                  <>
                    <Skeleton
                      key={i}
                      variant="rectangular"
                      height={'50px'}
                      width={'50px'}
                      sx={{ borderRadius: '4px' }}
                    />
                    <Box borderRight={`${index === 3 ? 'none' : '1px solid #cbd5e0'}`} />
                  </>
                ))}
              </Box>
            </Box>
          </ContentBox>
        </Column>
        <Column>
          <ContentBox
            containerClassName={'border-box'}
            paddingMode={'content'}
            title={'Non-Compliant Resources'}
            titleSize="medium"
            infoMsg="Trend of Non-Compliant Resources">
            <Box position={'relative'} height={'180px'} padding="20px">
              <Box display="flex" height="100%" justifyContent="space-between" alignItems="center">
                <Box marginLeft="20px">
                  <Skeleton variant="circular" width="130px" height="130px" />
                </Box>
                <Box>
                  <Skeleton variant="rectangular" height="100px" width="250px" sx={{ borderRadius: '8px' }} />
                </Box>
              </Box>
            </Box>
          </ContentBox>
        </Column>
      </ColumnsContainer>
      <ColumnsContainer marginBottom={'15px'}>
        <Column>
          <ContentBox
            containerClassName={'border-box'}
            paddingMode={'content'}
            title="Resource Compliance"
            titleSize="medium"
            infoMsg="Compliance Status of Resource(s)">
            <Box position={'relative'} height={'180px'} padding="20px">
              <Box display="flex" height="100%" justifyContent="space-between" alignItems="center">
                <Box marginLeft="20px">
                  <Skeleton variant="circular" width="130px" height="130px" />
                </Box>
                <Box>
                  <Skeleton variant="rectangular" height="100px" width="250px" sx={{ borderRadius: '8px' }} />
                </Box>
              </Box>
            </Box>
          </ContentBox>
        </Column>
        <Column>
          <ContentBox
            containerClassName={'border-box'}
            paddingMode={'content'}
            title="Remediations & Exceptions"
            titleSize="medium"
            infoMsg="Trend of Remediations & Exceptions">
            <Box position={'relative'} height={'165px'}>
              <Box
                marginLeft="15px"
                display="flex"
                flexDirection="column"
                marginRight="15px"
                marginBottom="15px"
                marginTop="15px">
                <Skeleton height="120px" width="100%" style={{ transform: 'none' }} />
                <Box margin="0px auto" display="flex" width="50%" justifyContent="center">
                  <Box margin="10px">
                    <Skeleton width="150px" />
                  </Box>
                  <Box margin="10px">
                    <Skeleton width="150px" />
                  </Box>
                </Box>
              </Box>
            </Box>
          </ContentBox>
        </Column>
        <Column>
          <ContentBox
            containerClassName={'border-box'}
            paddingMode={'content'}
            title={'Resource Trend'}
            titleSize="medium"
            infoMsg="Resource Trend Over Time">
            <Box position={'relative'} height={'165px'}>
              <Box
                marginLeft="15px"
                display="flex"
                flexDirection="column"
                marginRight="15px"
                marginBottom="15px"
                marginTop="15px">
                <Skeleton height="120px" width="100%" style={{ transform: 'none' }} />
                <Box margin="0px auto" display="flex" width="50%" justifyContent="center">
                  <Box margin="10px">
                    <Skeleton width="150px" />
                  </Box>
                  <Box margin="10px">
                    <Skeleton width="150px" />
                  </Box>
                </Box>
              </Box>
            </Box>
          </ContentBox>
        </Column>
      </ColumnsContainer>
      <ColumnsContainer marginBottom={'15px'}>
        <Column>
          <ContentBox
            containerClassName={'border-box'}
            paddingMode={'content'}
            title="Rule Compliance"
            titleSize="medium"
            infoMsg="Compliance Status of Rule(s)">
            <Box position={'relative'} height={'180px'} padding="20px">
              <Box display="flex" height="100%" justifyContent="space-between" alignItems="center">
                <Box marginLeft="20px">
                  <Skeleton variant="circular" width="130px" height="130px" />
                </Box>
                <Box>
                  <Skeleton variant="rectangular" height="100px" width="250px" sx={{ borderRadius: '8px' }} />
                </Box>
              </Box>
            </Box>
          </ContentBox>
        </Column>
        <Column>
          <ContentBox
            containerClassName={'border-box'}
            paddingMode={'content'}
            title="Resources"
            titleSize="medium"
            height={'220px'}
            infoMsg="Breakdown of Resource Compliance">
            <Box width={'100%'} height={'100%'} padding={'35px'} display={'flex'} justifyContent={'space-around'}>
              {Array.from(new Array(3)).map((i, index) => (
                <Box
                  borderRight={`${index === 2 ? 'none' : '1px solid #cbd5e0'}`}
                  display={'flex'}
                  flexDirection={'column'}
                  gap={'8px'}
                  alignItems={'center'}
                  width={'33%'}
                  height={'100px'}>
                  <Skeleton key={i} variant="rectangular" height={'60px'} width={'60px'} sx={{ borderRadius: '4px' }} />
                  <Skeleton key={i} variant="rectangular" height={'40px'} width={'50px'} sx={{ borderRadius: '4px' }} />
                </Box>
              ))}
            </Box>
          </ContentBox>
        </Column>
        <Column>
          <ContentBox
            containerClassName={'border-box'}
            paddingMode={'content'}
            title={'Compliance Over Times'}
            titleSize="medium"
            infoMsg="Compliance Score Over Time">
            <Box position={'relative'} height={'165px'}>
              <Box
                marginLeft="15px"
                display="flex"
                flexDirection="column"
                marginRight="15px"
                marginBottom="15px"
                marginTop="15px">
                <Skeleton height="120px" width="100%" style={{ transform: 'none' }} />
                <Box margin="0px auto" display="flex" width="50%" justifyContent="center">
                  <Box margin="10px">
                    <Skeleton width="150px" />
                  </Box>
                  <Box margin="10px">
                    <Skeleton width="150px" />
                  </Box>
                </Box>
              </Box>
            </Box>
          </ContentBox>
        </Column>
      </ColumnsContainer>
      <Box className="Borders RoundedCorners" marginBottom="15px" marginTop="15px">
        <Skeleton height="40px" style={{ transform: 'none' }} />
      </Box>
      <ContentBox>
        <Box display={'flex'} flexDirection={'column'} width={'100%'} gap={'15px'}>
          {Array.from(new Array(5)).map(item => (
            <Skeleton key={item} variant="rectangular" height={'100px'} width={'100%'} sx={{ borderRadius: '8px' }} />
          ))}
        </Box>
      </ContentBox>
    </PageContent>
  );
}

export default CompliacneSkleton;
