
import './style.scss';

function PageContent({ className = '', children }) {
  return (
    <div className={`PageContent ${className || ''}`}>
      {children}
    </div>
  );
}

export default PageContent;
