import { Checkbox } from '@mui/material';

const CheckBox = props => {
  return (
    <Checkbox
      {...props}
      sx={{
        '&.Mui-checked': {
          color: 'rgb(25, 75, 251)',
        },
      }}
      disableRipple
      // icon={
      //   <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      //     <rect width="14" height="14" rx="2" fill="#CBD5E0" />
      //   </svg>
      // }
      // checkedIcon={
      //   <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      //     <rect width="14" height="14" rx="2" fill="#194BFB" />
      //     <path
      //       d="M5.33535 9.73025C5.69091 10.0899 6.2404 10.0899 6.59596 9.73025L10.7333 5.54496C11.0889 5.18529 11.0889 4.62943 10.7333 4.26975C10.3778 3.91008 9.82828 3.91008 9.47273 4.26975L5.98182 7.80109L4.52727 6.3624C4.17172 6.00272 3.62222 6.00272 3.26667 6.3624C2.91111 6.72207 2.91111 7.27793 3.26667 7.6376L5.33535 9.73025Z"
      //       fill="white"
      //     />
      //   </svg>
      // }
    />
  );
};

export default CheckBox;
