import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown, FileText } from 'lucide-react';
import ReactMarkdown from 'react-markdown';

// ... [keeping all the styles exactly as they were]

const styles = {
  '@keyframes pulse': {
    '0%': { opacity: 0.4 },
    '50%': { opacity: 1 },
    '100%': { opacity: 0.4 },
  },
  // ... [all existing styles remain unchanged]
  thinkingMessage: {
    backgroundColor: '#f7fafc',
    color: '#718096',
    padding: '12px 16px',
    borderRadius: '8px',
    fontSize: '14px',
    fontFamily: 'Manrope',
    fontWeight: 500,
    fontStyle: 'italic',
    maxWidth: '70%',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  loadingDots: {
    display: 'inline-block',
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    backgroundColor: '#718096',
    animation: 'pulse 1s infinite',
    marginLeft: '4px',
  },
  container: {
    display: 'flex',
    minHeight: 'calc(100vh - 55px)',
    backgroundColor: '#ffffff',
    fontFamily: 'Manrope',
  },
  sidebar: {
    width: '260px',
    borderRight: '1px solid #e5e7eb',
    backgroundColor: '#ffffff',
    padding: '20px 0',
  },
  newNotebookBtn: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '8px 16px',
    margin: '0 16px',
    border: '1px solid #e5e7eb',
    borderRadius: '4px',
    color: '#3b82f6',
    backgroundColor: '#ffffff',
    cursor: 'pointer',
    fontSize: '14px',
    fontFamily: 'Manrope',
    fontWeight: 600,
  },
  searchInput: {
    width: 'calc(100% - 32px)',
    margin: '16px',
    padding: '8px 12px',
    border: '1px solid #e5e7eb',
    borderRadius: '4px',
    fontSize: '14px',
    fontFamily: 'Manrope',
  },
  notebookSection: {
    padding: '8px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#4a5568',
    fontSize: '14px',
    fontFamily: 'Manrope',
    fontWeight: 500,
  },
  notebookItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '6px 16px',
    color: '#4a5568',
    fontSize: '14px',
    cursor: 'pointer',
    fontFamily: 'Manrope',
    fontWeight: 500,
  },
  mainContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  contentArea: {
    flex: 1,
    padding: '32px',
    overflowY: 'auto',
    fontFamily: 'Manrope',
  },
  purpleLogo: {
    width: '48px',
    height: '48px',
    margin: '0 auto 24px',
  },
  title: {
    textAlign: 'center',
    color: '#1a202c',
    fontSize: '18px',
    marginBottom: '48px',
    fontFamily: 'Manrope',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '16px',
    marginBottom: '32px',
    fontFamily: 'Manrope',
  },
  card: {
    padding: '16px',
    border: '1px solid #e5e7eb',
    borderRadius: '8px',
    fontFamily: 'Manrope',
  },
  cardTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    marginBottom: '12px',
    fontSize: '15px',
    fontWeight: '600',
    fontFamily: 'Manrope',
    color: '#1a202c',
  },
  cardDescription: {
    fontSize: '14px',
    color: '#4a5568',
    marginBottom: '12px',
    lineHeight: '1.5',
    fontFamily: 'Manrope',
    fontWeight: 500,
  },
  cardLink: {
    color: '#3b82f6',
    fontSize: '14px',
    textDecoration: 'none',
    fontFamily: 'Manrope',
    fontWeight: '600',
  },
  infoText: {
    textAlign: 'center',
    fontSize: '14px',
    color: '#4a5568',
    maxWidth: '600px',
    margin: '0 auto 32px',
    lineHeight: '1.6',
    fontFamily: 'Manrope',
    fontWeight: 500,
  },
  inputContainer: {
    padding: '16px',
    borderTop: '1px solid #e5e7eb',
  },
  chatInput: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px',
    border: '1px solid #e5e7eb',
    borderRadius: '8px',
    backgroundColor: '#ffffff',
  },
  purpleIcon: {
    width: '20px',
    height: '20px',
    marginRight: '8px',
    color: '#3b82f6',
  },
  textInput: {
    flex: 1,
    border: 'none',
    outline: 'none',
    fontSize: '14px',
    fontFamily: 'Manrope',
    fontWeight: 500,
  },
  sendButton: {
    padding: '4px',
    color: '#3b82f6',
    cursor: 'pointer',
    border: 'none',
    background: 'none',
  },
  warningText: {
    textAlign: 'center',
    fontSize: '12px',
    color: '#718096',
    marginTop: '8px',
    fontFamily: 'Manrope',
    fontWeight: 500,
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  message: {
    maxWidth: '70%',
    padding: '12px 16px',
    borderRadius: '8px',
    lineHeight: '1.5',
    fontSize: '14px',
    fontFamily: 'Manrope',
  },
  userMessage: {
    backgroundColor: '#3b82f6',
    color: '#ffffff',
    marginLeft: 'auto',
    fontWeight: 500,
  },
  aiMessage: {
    backgroundColor: '#f7fafc',
    color: '#1a202c',
    fontWeight: 500,
  },
};

const ViperAIChat = () => {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const [isThinking, setIsThinking] = useState(false);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, isThinking]);

  const handleSend = async () => {
    if (inputText.trim()) {
      setMessages([...messages, { type: 'user', text: inputText }]);
      setInputText('');
      setIsThinking(true);

      try {
        const response = await fetch('http://10.0.2.53:5000/search', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: inputText,
          }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();

        setMessages(prev => [
          ...prev,
          {
            type: 'ai',
            text: data.response || data.message || JSON.stringify(data),
          },
        ]);
      } catch (error) {
        console.error('Error:', error);
        setMessages(prev => [
          ...prev,
          {
            type: 'ai',
            text: 'Sorry, I encountered an error processing your request. Please try again.',
          },
        ]);
      } finally {
        setIsThinking(false);
      }
    }
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const MessageContent = ({ text }) => {
    // Handle JSON responses
    if (typeof text === 'string' && text.startsWith('{')) {
      try {
        const parsedResponse = JSON.parse(text);
        if (parsedResponse && parsedResponse?.data && parsedResponse?.data?.results) {
          return <ReactMarkdown>{parsedResponse?.data?.results}</ReactMarkdown>;
        }
      } catch (e) {
        // If JSON parsing fails, fall back to regular text
        console.error('Error parsing JSON:', e);
      }
    }
    return <ReactMarkdown>{text}</ReactMarkdown>;
  };

  return (
    <div style={styles.container}>
      {/* Sidebar - keeping exactly as it was */}
      <div style={styles.sidebar}>
        <button style={styles.newNotebookBtn}>
          <span>+</span>
          <span>New Notebook</span>
        </button>

        <input type="text" placeholder="Filter notebooks" style={styles.searchInput} />

        <div style={styles.notebookSection}>
          <span>My Notebooks</span>
          <ChevronDown size={16} />
        </div>

        <div style={styles.notebookSection}>
          <span>Shared Notebooks</span>
          <ChevronDown size={16} />
        </div>

        <div style={{ marginTop: '8px' }}>
          <div style={styles.notebookItem}>
            <FileText size={16} />
            <span>test</span>
          </div>
          <div style={styles.notebookItem}>
            <FileText size={16} />
            <span>Password Spraying Attack</span>
          </div>
          <div style={styles.notebookItem}>
            <FileText size={16} />
            <span>Notebook May 01, 2024</span>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div style={styles.mainContent}>
        <div style={styles.contentArea}>
          {messages.length === 0 ? (
            <>
              <div style={styles.purpleLogo}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 256 256"
                  className="ai-icon">
                  <path
                    fill="#3b82f6"
                    d="m197.58 129.06l-51.61-19l-19-51.65a15.92 15.92 0 0 0-29.88 0L78.07 110l-51.65 19a15.92 15.92 0 0 0 0 29.88L78 178l19 51.62a15.92 15.92 0 0 0 29.88 0l19-51.61l51.65-19a15.92 15.92 0 0 0 0-29.88ZM140.39 163a15.87 15.87 0 0 0-9.43 9.43l-19 51.46L93 172.39a15.87 15.87 0 0 0-9.39-9.39l-51.46-19l51.46-19a15.87 15.87 0 0 0 9.39-9.39l19-51.46l19 51.46a15.87 15.87 0 0 0 9.43 9.43l51.46 19ZM144 40a8 8 0 0 1 8-8h16V16a8 8 0 0 1 16 0v16h16a8 8 0 0 1 0 16h-16v16a8 8 0 0 1-16 0V48h-16a8 8 0 0 1-8-8Zm104 48a8 8 0 0 1-8 8h-8v8a8 8 0 0 1-16 0v-8h-8a8 8 0 0 1 0-16h8v-8a8 8 0 0 1 16 0v8h8a8 8 0 0 1 8 8Z"
                  />
                </svg>
              </div>

              <h1 style={styles.title}>Viper AI is your AI security analyst.</h1>

              <div style={styles.grid}>
                {['Threat Actor', 'TTP', 'IOC', 'Asset', 'Malware', 'Anomaly'].map(category => (
                  <div key={category} style={styles.card}>
                    <div style={styles.cardTitle}>
                      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                        <circle cx="11" cy="11" r="8" />
                        <path d="M21 21l-4.35-4.35" />
                      </svg>
                      {category}
                    </div>
                    <p style={styles.cardDescription}>Search for {category.toLowerCase()} related information</p>
                    <a href="#" style={styles.cardLink}>
                      Examples →
                    </a>
                  </div>
                ))}
              </div>

              <p style={styles.infoText}>
                Viper AI supports questions associated with OS events, indicators, threat intelligence feeds, Shield
                logs, and the fields within them. The default time range for event search is the last 24 hours.
              </p>
            </>
          ) : (
            <div style={styles.messageContainer}>
              {messages.map((message, index) => (
                <div
                  key={index}
                  style={{
                    ...styles.message,
                    ...(message.type === 'user' ? styles.userMessage : styles.aiMessage),
                  }}>
                  <MessageContent text={message.text} />
                </div>
              ))}
              {isThinking && (
                <div style={styles.thinkingMessage}>
                  <span>Thinking</span>
                  <span style={styles.loadingDots}></span>
                  <span style={styles.loadingDots}></span>
                  <span style={styles.loadingDots}></span>
                </div>
              )}
              <div ref={messagesEndRef} />
            </div>
          )}
        </div>

        <div style={styles.inputContainer}>
          <div style={styles.chatInput}>
            <div style={styles.purpleIcon}>
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 256 256" className="ai-icon">
                <path
                  fill="currentColor"
                  d="m197.58 129.06l-51.61-19l-19-51.65a15.92 15.92 0 0 0-29.88 0L78.07 110l-51.65 19a15.92 15.92 0 0 0 0 29.88L78 178l19 51.62a15.92 15.92 0 0 0 29.88 0l19-51.61l51.65-19a15.92 15.92 0 0 0 0-29.88ZM140.39 163a15.87 15.87 0 0 0-9.43 9.43l-19 51.46L93 172.39a15.87 15.87 0 0 0-9.39-9.39l-51.46-19l51.46-19a15.87 15.87 0 0 0 9.39-9.39l19-51.46l19 51.46a15.87 15.87 0 0 0 9.43 9.43l51.46 19ZM144 40a8 8 0 0 1 8-8h16V16a8 8 0 0 1 16 0v16h16a8 8 0 0 1 0 16h-16v16a8 8 0 0 1-16 0V48h-16a8 8 0 0 1-8-8Zm104 48a8 8 0 0 1-8 8h-8v8a8 8 0 0 1-16 0v-8h-8a8 8 0 0 1 0-16h8v-8a8 8 0 0 1 16 0v8h8a8 8 0 0 1 8 8Z"
                />
              </svg>
            </div>
            <input
              type="text"
              value={inputText}
              onChange={e => setInputText(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Ask Viper AI..."
              style={styles.textInput}
            />
            <button onClick={handleSend} style={styles.sendButton}>
              <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                <path d="M22 2L11 13" />
                <path d="M22 2L15 22L11 13L2 9L22 2z" />
              </svg>
            </button>
          </div>
          <p style={styles.warningText}>Viper AI can make mistakes. Consider checking important information.</p>
        </div>
      </div>
    </div>
  );
};
export default ViperAIChat;
