import { NoDataBox } from '@root/assets/svg/SVGComponents';
import React from 'react';

const NoData = ({ message = undefined, height = '100%', hideImage = false, isEventsTable = false, fetchTimeRange }) => (
  // <div
  //   style={{
  //     position: 'relative',
  //     height: height,
  //     display: 'flex',
  //     alignItems: 'center',
  //     justifyContent: 'center',
  //     flexDirection: 'column',
  //   }}>
  //   {!hideImage && (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="44"
  //       height="36"
  //       viewBox="0 0 44 36"
  //       fill="none"
  //       style={{ height: '20px', marginBottom: '10px' }}>
  //       <path
  //         d="M43.0194 30.825L25.1871 1.7325C24.8582 1.1925 24.4029 0.765 23.8465 0.4725C23.29 0.1575 22.6324 0 22 0C21.3676 0 20.7353 0.1575 20.1788 0.4725C19.5971 0.765 19.1418 1.1925 18.8129 1.7325L0.980588 30.825C0.677059 31.3425 0.5 31.9275 0.5 32.535C0.5 33.1425 0.677059 33.75 0.980588 34.2675C1.30941 34.8075 1.76471 35.235 2.34647 35.5275C2.90294 35.8425 3.53529 36 4.16765 36H39.8324C40.4647 36 41.0971 35.8425 41.6535 35.5275C42.2353 35.235 42.6906 34.8075 43.0194 34.2675C43.3229 33.75 43.5 33.1425 43.5 32.535C43.5 31.9275 43.3229 31.3425 43.0194 30.825ZM22 31.3425C20.7353 31.3425 19.6982 30.375 19.6982 29.1825C19.6982 27.99 20.7353 27 22 27C23.2647 27 24.3018 27.9675 24.3018 29.1825C24.3018 30.3975 23.2647 31.3425 22 31.3425ZM24.3018 21.9825C24.3018 23.0625 23.3912 23.94 22.2276 23.94H21.7724C20.6341 23.94 19.6982 23.0625 19.6982 21.9825V12.645C19.6982 11.565 20.6341 10.71 21.7724 10.71H22.2276C23.3912 10.71 24.3018 11.565 24.3018 12.645V21.9825Z"
  //         fill="#484D56"
  //       />
  //     </svg>
  //   )}
  //   <div style={{ fontFamily: 'Manrope', fontSize: '20px', color: '#484D56', fontWeight: '600' }}>
  //     {message || 'No Data Found!'}
  //   </div>
  // </div>
  <div
    style={{
      position: 'relative',
      height: height,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '8px',
    }}>
    {!hideImage && <NoDataBox height="80px" width="80px" />}
    <div style={{ fontFamily: 'Manrope', fontSize: '14px', color: '#484D56', fontWeight: '600' }}>
      {isEventsTable && fetchTimeRange && fetchTimeRange === '24h'
        ? `Sorry, we couldn’t find any data in last ${fetchTimeRange}`
        : message || `Sorry, we couldn't find any data!`}
    </div>
  </div>
);

export default NoData;
