import React from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Button from "@mui/material/Button";
import Text from "@root/components/v2/ui-elements/Text";
import "./style.scss";
import Tooltip from "@root/components/v2/ui-elements/Tooltip";
function SelectedRegionsModal({ open, regions, isRegionAny, type, onClose }) {
  return (
    <Dialog open={open}>
      <DialogTitle>
        {type === "resources" ? "Selected Resources" : "Selected Regions"}
      </DialogTitle>

      <DialogContent
        style={{ overflowX: "hidden" }}
        className="CustomScrollbar"
      >
        {!isRegionAny && !regions.length ? (
          <div
            style={{
              width: "300px",
              padding: "16px",
              textAlign: "center",
              fontStyle: "italic",
            }}
          >
            No {type === "resources" ? "resources" : "regions"} selected
          </div>
        ) : null}

        {isRegionAny ? (
          <div
            style={{
              width: "300px",
              padding: "16px",
              textAlign: "center",
              fontStyle: "italic",
            }}
          >
            Any {type === "resources" ? "resource" : "region"} currently
            available or may be available in the future are selected.
          </div>
        ) : type === "resources" ? (
          regions.map((r) => (
            <div key={r} className="CreatePolicy__SelectedRegionModalItem">
              <span>●</span>
              <Tooltip title={r.resource_id}>
                <Text className="SingleLine"> {r.resource_id}</Text>
              </Tooltip>
            </div>
          ))
        ) : (
          regions.map((r) => (
            <div key={r} className="CreatePolicy__SelectedRegionModalItem">
              <span>●</span>
              <span> {r}</span>
            </div>
          ))
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default SelectedRegionsModal;
