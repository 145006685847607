import { Box, Skeleton } from '@mui/material';

import TableSkeleton from '@root/components/table/skeleton';
import CloudProviderBarSkeleton from '@root/components/v2/content/CloudProviderBar/skeleton';
import ContentBox from '@root/components/v2/content/ContentBox';
// import PageContainer from '@root/components/v2/layout/PageContainer';
import PageContent from '@root/components/v2/layout/PageContent';
// import PageHeader from '@root/components/v2/layout/PageHeader';

function WorkloadSkeleton() {
  return (
    <PageContent>
      <Skeleton
        variant="rectangular"
        height="15px"
        width={'100px'}
        sx={{ marginBottom: '15px', borderRadius: '8px' }}
      />
      <CloudProviderBarSkeleton />

      <Box className="Borders RoundedCorners" marginBottom="15px" marginTop="15px">
        <Skeleton height="30px" style={{ transform: 'none' }} />
      </Box>
      <ContentBox paddingMode="content" marginBottom="15px">
        <TableSkeleton />
      </ContentBox>
    </PageContent>
  );
}

export default WorkloadSkeleton;
