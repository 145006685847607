import { Box, Skeleton } from '@mui/material';

import TableSkeleton from '@root/components/table/skeleton';
import PageContent from '@root/components/v2/layout/PageContent';
import PageHeaderSkeleton from '@root/components/v2/layout/PageHeader/Skeleton';

function RbacSkleton() {
  return (
    <PageContent>
      <PageHeaderSkeleton />

        <Box
          className="Borders RoundedCorners"
       
          marginBottom="15px"
          marginTop="15px">
          <Skeleton height="30px" style={{ transform: 'none' }} />
        </Box>
    

        <TableSkeleton />
    
    </PageContent>
  );
}

export default RbacSkleton;
