import React from 'react';
import './style.scss';

const FONT_NAME_WEIGHTS = {
  regular: 400,
  medium: 500,
  'semi-bold': 600,
  bold: 700,
  'extra-bold': 800,
  light: 300,
  'extra-light': 200,
};

const Text = React.forwardRef(
  (
    {
      element = 'div',
      to = undefined,
      children = undefined,
      font = 'regular',
      color = undefined,
      marginBottom = undefined,
      marginTop = undefined,
      paddingTop = undefined,
      lineHeight = undefined,
      fontSize = undefined,
      fontWeight = undefined,
      style = {},
      className = undefined,
      ...props
    },
    ref,
  ) => {
    const Element = element;

    return (
      <Element
        ref={ref}
        className={`Text ${className || ''}`}
        {...(to ? to : { to })}
        {...props}
        style={{
          ...style,
          ...(marginTop && { marginTop }),
          ...(marginBottom && { marginBottom }),
          ...(paddingTop && { paddingTop }),
          ...(color && { color }),
          ...(lineHeight && { lineHeight }),
          ...(fontSize && { fontSize }),
          ...(font && !fontWeight && { fontWeight: FONT_NAME_WEIGHTS[font] }),
          ...(fontWeight && { fontWeight }),
        }}>
        {children}
      </Element>
    );
  },
);

Text.displayName = 'Text';

export default Text;
