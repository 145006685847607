// http://localhost:3000/identity/overview?bc=%5B%7B%22t%22%3A%22Home%22%2C%22p%22%3A%22%2Fdashboard%22%7D%2C%7B%22t%22%3A%22Overview%22%2C%22p%22%3A%22%2Fidentity%2Foverview%22%7D%5D

import { useMemo } from 'react';
import { useQuery } from '@hooks/useQuery';

declare const window: any;
type BreadcrumbData = { title: string; path?: string };
type BreadcrumbDataForUrl = { t: string; p: string };

const removeBreadcrumbFromUrl = (params?: {
  otherQueryParamKeysToRemove?: string[];
  queryParamsToAdd?: Record<string, string>;
}) => {
  const { otherQueryParamKeysToRemove, queryParamsToAdd } = params || {};
  const currentUrl = new URL(window.location.href);
  const currentUrlQueryParams = currentUrl.searchParams;
  currentUrlQueryParams.delete('bc');

  if (otherQueryParamKeysToRemove?.length) {
    for (const key of otherQueryParamKeysToRemove) {
      currentUrlQueryParams.delete(key);
    }
  }

  if (queryParamsToAdd) {
    for (const key in queryParamsToAdd) {
      currentUrlQueryParams.set(key, queryParamsToAdd[key]);
    }
  }

  return `${currentUrl.pathname}?${currentUrlQueryParams.toString()}`;
};

/**
 * Create a new URL with the breadcrumb query parameter included.
 * @param param Object parameter
 * @param param.path The path of the url. This can also include query parameters (so not precisely a path), e.g. /identity/policies?foo=bar
 * @param param.breadcrumbs The breadcrumbs data
 * @returns
 */
const buildBreadcrumbUrl = ({ path, breadcrumbs }: { path?: string; breadcrumbs: BreadcrumbData[] }) => {
  const _url = new URL(path ? `${window.location.origin}${path}` : window.location.href);
  const _queryParams = _url.searchParams;

  const _breadcrumbData = breadcrumbs?.map(b => ({ t: b.title, p: b.path }));

  _queryParams.set('bc', JSON.stringify(_breadcrumbData));

  return `${_url.pathname}?${_queryParams.toString()}`;
};

/**
 * Create a new URL with the breadcrumb query parameter included. This will append new breadcrumb parts to existing
 * breadcrumbs in the url. If breadcrumb data does not exist in the page URL, this function will pick up breadcrumb
 * data specified in the <Breadcrumbs /> component on the page.
 * @param param Object parameter
 * @param param.path The path of the url. This can also include query parameters (so not precisely a path), e.g. /identity/policies?foo=bar
 * @param param.breadcrumbs The breadcrumbs data
 * @returns
 */
const appendBreadcrumbsToUrl = ({ path, breadcrumbs }: { path?: string; breadcrumbs: BreadcrumbData[] }) => {
  const currentUrl = new URL(window.location.href);
  const currentUrlQueryParams = currentUrl.searchParams;

  const breadcrumbJSON = currentUrlQueryParams.get('bc') || null;

  const existingBreadcrumbs: BreadcrumbDataForUrl[] =
    breadcrumbJSON === null
      ? ((window.BREADCRUMB_PROPS?.breadcrumbs || []) as BreadcrumbData[]).map(b => ({ t: b.title, p: b.path }))
      : JSON.parse(breadcrumbJSON);

  // If the last entry in existingBreadcrumbsInUrl does not have a path,
  // we take the path from the page URL and set it to the last item.
  // This path will then be used as the url for the breadcrumb link.

  const lastItemInBreadcrumbs = existingBreadcrumbs[existingBreadcrumbs.length - 1];

  if (existingBreadcrumbs.length && lastItemInBreadcrumbs && !lastItemInBreadcrumbs.p) {
    lastItemInBreadcrumbs.p = removeBreadcrumbFromUrl();
  }

  const _breadcrumbData: BreadcrumbDataForUrl[] = [
    ...[
      ...existingBreadcrumbs.slice(0, existingBreadcrumbs.length - 1),
      ...(lastItemInBreadcrumbs ? [lastItemInBreadcrumbs] : []),
    ],
    ...breadcrumbs.map(b => ({ t: b.title, p: b.path } as BreadcrumbDataForUrl)),
  ];

  const newUrl = new URL(`${window.location.origin}${path}`);
  const newUrlQueryParams = newUrl.searchParams;

  newUrlQueryParams.set('bc', JSON.stringify(_breadcrumbData));

  return `${newUrl.pathname}?${newUrlQueryParams.toString()}`;
};

function useBreadcrumbs() {
  const queryParams: URLSearchParams = useQuery();

  const breadcrumbData = useMemo<BreadcrumbData[]>(() => {
    const json = queryParams.get('bc');

    if (!json) return null;

    return JSON.parse(json).map((d: any) => ({ title: d.t, path: d.p }));
  }, [queryParams]);

  return { breadcrumbData };
}

export { useBreadcrumbs, buildBreadcrumbUrl, appendBreadcrumbsToUrl, removeBreadcrumbFromUrl, type BreadcrumbData };
