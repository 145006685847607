import './TopNavbar.scss';
import ProfileDropdown from './ProfileDropdown';
import HeaderContent from '@root/components/v2/global/HeaderContent';
import NotificationsPanel from './NotificationsPanel';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { kFormatter } from '@root/utilities/common';
import { fetchNotificationlistData } from './NotificationsPanel/action';
import usePubSub from '@root/hooks/usePubSub';
import { useQueryClient } from '@tanstack/react-query';
import RefreshIcon from '@mui/icons-material/Refresh';
import NotificationsIcon from '@mui/icons-material/Notifications';
const TopNavbarNew = () => {
  const [showNotificationsPanel, setShowNotificationsPanel] = useState(false);
  const notificationCount = useSelector(state => state.notification.notification_list.unread_count);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [notificationFromSocket, setNotificationFromSocket] = useState();
  const [todayNotificationsList, setTodayNotificationsList] = useState([]);
  const { publish } = usePubSub();
  const queryClient = useQueryClient();
  const refreshKey = useRef(1);
  useEffect(() => {
    (async () => {
      if (!showNotificationsPanel) {
        setLoading(true);
        await fetchNotificationlistData(dispatch, 1, false);
        setLoading(false);
      }
    })();
  }, [showNotificationsPanel, dispatch]);

  const sidebarWidth = useSelector(state => state.common.sidebarWidth);
  usePubSub(
    'notification',
    useCallback(data => {
      setNotificationFromSocket(data);
    }, []),
  );
  useEffect(() => {
    if (notificationFromSocket) {
      setTodayNotificationsList(prev => {
        const isDuplicate = prev.find(notification => notification.id === notificationFromSocket.id);

        if (!isDuplicate) {
          return [notificationFromSocket, ...prev];
        }
        // console.log(new Set([...prev]), 'knjnnj');
        return [...new Set(prev)];
      });
    }
  }, [notificationFromSocket]);
  // console.log(todayNotificationsList, 'todayNotificationsList');

  const refreshHandler = useCallback(() => {
    queryClient.invalidateQueries();
    refreshKey.current += 1;
    publish('refresh_page', refreshKey.current);
  }, [publish, queryClient]);

  return (
    <div className="TopNavbar__Container" style={{ maxWidth: `calc(100vw - ${sidebarWidth}px)` }}>
      <div className="TopNavbar__ScopeFilters">
        <HeaderContent />
      </div>
      <div style={{ flexGrow: '1' }} />
      <div className="TopNavbar__UtilButtons">
        {/* refresh button */}
        <Box className="UtilButton__Container" onClick={refreshHandler}>
          <RefreshIcon sx={{ color: '#fff', height: '25px' }} />
        </Box>

        {/* notif button */}
        <Box className="UtilButton__Container" onClick={() => setShowNotificationsPanel(prev => !prev)}>
          <NotificationsIcon sx={{ color: '#fff', height: '23px' }} />
          {notificationCount > 0 && <div className="NavbarNotifications__Counter">{kFormatter(notificationCount)}</div>}
        </Box>
      </div>

      <div className="TopNavbar__Profile">
        <ProfileDropdown />
      </div>
      {showNotificationsPanel && (
        <NotificationsPanel
          firstLoading={loading}
          isOpen={showNotificationsPanel}
          setShowNotificationsPanel={setShowNotificationsPanel}
          notificationFromSocket={notificationFromSocket}
          setTodayNotificationsList={setTodayNotificationsList}
          todayNotificationsList={todayNotificationsList}
          onClose={() => {
            setShowNotificationsPanel(false);
            dispatch({
              type: 'RESET_BELL_PAGE',
            });
          }}
        />
      )}
    </div>
  );
};

export default TopNavbarNew;
