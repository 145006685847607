import { Box, Grid, Skeleton } from '@mui/material';

import CloudProviderBarSkeleton from '@root/components/v2/content/CloudProviderBar/skeleton';

import PageContent from '@root/components/v2/layout/PageContent';

import ContentBox from '@root/components/v2/content/ContentBox';
import PageHeaderSkeleton from '@root/components/v2/layout/PageHeader/Skeleton';
import './risk.scss';

function RiskSkeleton() {
  const tilesData = [
    'Network',

    'Encryption',

    'Data Security',

    'Exposed Secrets',

    'Identity',

    'High Availability',
    'Logging & Monitoring',

    'Vulnerabilities',

    'Lateral Movement',
  ];
  return (
    <PageContent>
      <PageHeaderSkeleton />
      <CloudProviderBarSkeleton />
      <Box display={'flex'} flexDirection={'column'} gap={'15px'}>
        <Grid
          className="top_card m-0"
          style={{
            display: 'grid',
            // justifyContent: 'space-between',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gap: '15px',
            // border: '1px solid var(--border-color-light)',
            // borderRadius: '15px',
          }}>
          <ContentBox style={{ height: '100%' }}>
            <Box display={'flex'} flexDirection={'column'} gap={'30px'}>
              <Skeleton variant="rectangular" width={'140px'} sx={{ borderRadius: '4px' }} />
              <Skeleton variant="rectangular" width={'80px'} sx={{ borderRadius: '4px' }} />
            </Box>
          </ContentBox>
          <ContentBox style={{ height: '100%' }}>
            <Box display={'flex'} flexDirection={'column'} gap={'30px'}>
              <Skeleton variant="rectangular" width={'200px'} sx={{ borderRadius: '4px' }} />
              <Skeleton variant="rectangular" width={'200px'} sx={{ borderRadius: '4px' }} />
            </Box>
          </ContentBox>
          <ContentBox style={{ height: '100%' }}>
            <Box display={'flex'} flexDirection={'column'} gap={'30px'}>
              <Skeleton variant="rectangular" width={'200px'} sx={{ borderRadius: '4px' }} />
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <Skeleton variant="rectangular" width={'80px'} height={'20px'} sx={{ borderRadius: '4px' }} />
                <Skeleton variant="rectangular" width={'80px'} height={'20px'} sx={{ borderRadius: '4px' }} />
                <Skeleton variant="rectangular" width={'80px'} height={'20px'} sx={{ borderRadius: '4px' }} />
              </Box>
            </Box>
          </ContentBox>
          <ContentBox style={{ height: '100%' }}>
            <Box display={'flex'} flexDirection={'column'} gap={'30px'}>
              <Skeleton variant="rectangular" width={'200px'} sx={{ borderRadius: '4px' }} />
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <Skeleton variant="rectangular" width={'60px'} height={'20px'} sx={{ borderRadius: '4px' }} />
                <Skeleton variant="rectangular" width={'60px'} height={'20px'} sx={{ borderRadius: '4px' }} />
                <Skeleton variant="rectangular" width={'60px'} height={'20px'} sx={{ borderRadius: '4px' }} />
                <Skeleton variant="rectangular" width={'60px'} height={'20px'} sx={{ borderRadius: '4px' }} />
              </Box>
            </Box>
          </ContentBox>
        </Grid>

        <ContentBox style={{ width: '100%' }} title={'Risk Over Time'} height={'370px'}>
          <Box display={'flex'} flexDirection={'column'} gap={'15px'}>
            <Skeleton variant="rectangular" height={'260px'} sx={{ borderRadius: '8px' }} />
            <Box display={'flex'} gap={'15px'} alignItems={'center'} justifyContent={'center'}>
              <Skeleton variant="rectangular" height={'20px'} width={'100px'} sx={{ borderRadius: '8px' }} />
              <Skeleton variant="rectangular" height={'20px'} width={'100px'} sx={{ borderRadius: '8px' }} />
              <Skeleton variant="rectangular" height={'20px'} width={'100px'} sx={{ borderRadius: '8px' }} />
              <Skeleton variant="rectangular" height={'20px'} width={'100px'} sx={{ borderRadius: '8px' }} />
            </Box>
          </Box>
        </ContentBox>
        <Grid container sx={{ display: 'flex', gap: '12px' }} margin={'15px'} spacing={1.8} marginRight={'0px'}>
          {tilesData.map(() => {
            return (
              <Grid xs={3.8999}>
                <ContentBox style={{ height: '140px' }}>
                  <Grid sx={{ my: '15px', display: 'flex', justifyContent: 'space-between' }}>
                    <Skeleton height={20} width="100px" style={{ transform: 'none' }} />
                    <Skeleton height={20} width="50px" style={{ transform: 'none' }} />
                  </Grid>
                  <Grid
                    sx={{
                      my: '22px',
                      width: '100%',
                      marginTop: '0px',
                      // marginRight: '90px',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}>
                    <Grid width={'100%'}>
                      <Grid sx={{ display: 'flex', mb: '12px' }}>
                        <Skeleton height={20} width="70px" style={{ transform: 'none' }} />
                      </Grid>
                      <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Skeleton height={20} width="70px" style={{ transform: 'none' }} />
                        <Skeleton height={20} width="150px" style={{ transform: 'none' }} />
                      </Grid>
                    </Grid>
                  </Grid>
                </ContentBox>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </PageContent>
  );
}

export default RiskSkeleton;
