import { Box, Grid, Skeleton } from '@mui/material';

import CloudProviderBarSkeleton from '@root/components/v2/content/CloudProviderBar/skeleton';
import ContentBox from '@root/components/v2/content/ContentBox';
import PageContent from '@root/components/v2/layout/PageContent';
import PageHeaderSkeleton from '@root/components/v2/layout/PageHeader/Skeleton';

export const OpenThreatSkeleton = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" height="264px" padding="15px" marginBottom="10px">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        height="100%"
        width="100%"
        justifyContent="space-between">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
          borderRadius="5px"
          component="div">
          <Box minWidth="100%" minHeight="100px">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent={'space-between'}
              gap={'8px'}
              height={'90px'}
              alignItems={'center'}>
              <Skeleton width="34%" height="120px" />
              <Skeleton width="34%" height="120px" />
              <Skeleton width="34%" height="120px" />
            </Box>
            <Grid sx={{ width: '100%' }}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent={'space-between'}
                gap={'8px'}
                alignItems={'center'}
                height={'90px'}>
                <Skeleton width="34%" height="120px" />
                <Skeleton width="34%" height="120px" />
                <Skeleton width="34%" height="120px" />
              </Box>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const TopTriggeredSkeleton = () => {
  return (
    <ContentBox
      titleSize="medium"
      title={
        <div className="TitleTab__Container" style={{ padding:'10px'}}>
          <div style={{ height: '100%',  }}>
            Top Triggered TTPs
          </div>
          <div style={{ height: '100%' }} >
            Top Suspicious Entities
          </div>
        </div>
      }
      titleContainerStyle={{
        // border: "0px",
        fontSize: '16px !important',
        color: 'var(--keyinsight-text-color)',
        // background: '#F7FAFC',
        // backgroundColor: 'var(--keyinsight-box-header-background)',
      }}
      paddingMode="content"
      containerClassName={'border-box-no-pad'}
      height="264px"
      style={{
        width: '100%',
      }}>
      <Box sx={{ width: '100%', height: '100%', padding: '8px', flexDirection: 'column', gap: '8px', display: 'flex' }}>
        {Array.from(new Array(5)).map(idx => (
          <Box key={idx} width={'100%'} height={'30px'} display={'flex'} justifyContent={'space-between'}>
            <Box sx={{ display: 'flex', gap: '8px', width: '100%', marginRight: '8px' }}>
              <Skeleton variant={'rectangular'} width={'100%'} height={'30px'} sx={{ borderRadius: '8px' }} />
            </Box>
            <Box>
              <Skeleton variant={'rectangular'} width={'30px'} height={'30px'} sx={{ borderRadius: '8px' }} />
            </Box>
          </Box>
        ))}
      </Box>
    </ContentBox>
  );
};

export const ThreatsSkeleton = () => {
  return (
    <ContentBox
      titleSize="medium"
      title="Threats - Created vs Resolved"
      titleContainerStyle={{
        fontSize: '16px !important',
        color: 'var(--keyinsight-text-color)',
      }}
      paddingMode="content"
      containerClassName={'border-box-no-pad'}
      height="448px"
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '16px',
        }}>
        <Box height={'320px'} width={'100%'}>
          <Skeleton
            variant="rectangular"
            height={'320px'}
            width={'100%'}
            sx={{
              borderRadius: '8px',
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', gap: ' 10px', width: '100%', justifyContent: 'center', marginTop: '20px' }}>
          <Box sx={{ width: '15%', height: '30px', display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Skeleton variant="circular" width={'20px'} height={'20px'} /> <Skeleton width={'100%'} height={'30px'} />
          </Box>
          <Box sx={{ width: '15%', height: '30px', display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Skeleton variant="circular" width={'20px'} height={'20px'} /> <Skeleton width={'100%'} height={'30px'} />
          </Box>
        </Box>
      </Box>
    </ContentBox>
  );
};

export const InventoryComponentSkeleton = () => {
  return (
    <ContentBox
      titleSize="medium"
      title="Inventory"
      titleContainerStyle={{
        // border: "0px",
        fontSize: '16px !important',
        color: 'var(--keyinsight-text-color)',
        // background: '#F7FAFC',
        // backgroundColor: 'var(--keyinsight-box-header-background)',
      }}
      paddingMode="content"
      containerClassName={'border-box-no-pad'}
      height="448px"
      style={{
        width: '100%',
      }}>
      <Box sx={{ width: '100%', height: '100%', padding: '8px', flexDirection: 'column', gap: '8px', display: 'flex' }}>
        {Array.from(new Array(10)).map(idx => (
          <Box key={idx} width={'100%'} height={'30px'} display={'flex'} justifyContent={'space-between'}>
            <Box sx={{ display: 'flex', gap: '8px', width: '100%', marginRight: '8px' }}>
              <Skeleton variant={'rectangular'} width={'30px'} height={'30px'} sx={{ borderRadius: '10px' }} />
              <Skeleton variant={'rectangular'} width={'100%'} height={'30px'} sx={{ borderRadius: '10px' }} />
            </Box>
            <Box>
              <Skeleton variant={'rectangular'} width={'30px'} height={'30px'} sx={{ borderRadius: '10px' }} />
            </Box>
          </Box>
        ))}
      </Box>
    </ContentBox>
  );
};

function DashboardSkeleton() {
  return (
    <PageContent className="PageContent__Skeleton_pt">
      <PageHeaderSkeleton />
      <CloudProviderBarSkeleton />

      <Box display="flex" flexDirection="row" gap="12px" marginBottom={'15px'}>
        {['Security Score', 'Risk Trends', 'Compliance', 'Runtime Protection'].map(title => (
          <ContentBox
            key={title}
            title={title}
            paddingMode="content"
            style={{ flexGrow: 1, width: 'calc(25% - 15px)', height: '442px' }}>
            {/* Set a fixed width for each ContentBox */}

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              height="406px"
              padding="15px"
              paddingTop={title === 'Security Score' ? '30px' : '15px'}>
              {title === 'Compliance' ? (
                <>
                  {/* <Box
                      width="100%"
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      marginBottom="10px">
                      <Skeleton width="100px" />
                      <Skeleton width="100px" />
                    </Box> */}
                  <Grid
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '20px',
                      width: '100%',

                      // pt: '15px',
                    }}>
                    {Array.from(new Array(6)).map(i => (
                      <Grid
                        key={i}
                        sx={{
                          // border: '1px solid #cbd5e0',

                          // borderRadius: '50%',
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                        }}>
                        <Grid>
                          <Box
                            width="100%"
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            marginTop={'5px'}>
                            <Skeleton variant="circular" width="40px" height="40px" />
                          </Box>
                        </Grid>
                        <Grid sx={{ width: '100%', marginLeft: '10px' }}>
                          <Box width="100%" display="flex" flexDirection="row" justifyContent="flex-end">
                            <Skeleton width="30px" />
                          </Box>
                          <Box width="100%">
                            <Skeleton width="100%" />
                          </Box>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </>
              ) : (
                <>
                  {' '}
                  <Box width="100%" display="flex" justifyContent="center" marginBottom="20px">
                    {title === 'Risk Trends' || title === 'Resource Trend' ? (
                      <Skeleton
                        variant="rectangular"
                        height="190px"
                        sx={{ width: '100%', flexGrow: 1, borderRadius: '8px' }}
                      />
                    ) : (
                      <Skeleton variant="circular" width="165px" height="165px" />
                    )}
                  </Box>
                  <Box flexGrow={1} height="10px" width="100%" />
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    marginBottom="10px">
                    <Skeleton width="80px" />
                    {title === 'Risk Trends' && <Skeleton width="70px" />}
                    <Skeleton width="80px" />
                  </Box>
                  <Grid sx={{ width: '100%' }}>
                    {Array.from(new Array(title === 'Cloud Provider' ? 3 : 4)).map((i, index) => (
                      <Box
                        key={i}
                        width="100%"
                        display="flex"
                        flexDirection="column"
                        gap={title === 'Cloud Provider' ? '15px' : '10px'}>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          marginTop={index === 0 ? '0px' : title === 'Cloud Provider' ? '20px' : '8px'}>
                          <Skeleton width="70px" />
                          {title === 'Risk Trends' && <Skeleton width="70px" />}
                          <Skeleton width="70px" />
                        </Box>
                      </Box>
                    ))}
                  </Grid>
                </>
              )}
            </Box>
          </ContentBox>
        ))}
      </Box>

      <Box display="flex" flexDirection="row" gap="15px">
        {['Top 5 Risk Recommendations', 'Internet Exposed Resources', 'CQ Shield Priority'].map(title => (
          <ContentBox
            key={title}
            title={title}
            paddingMode="content"
            style={{ flexGrow: 1, width: 'calc(33.33% - 15px)', height: '440px' }}>
            {/* Set a fixed width for each ContentBox */}

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              height="440px"
              padding="15px"
              overflow="hidden">
              {title === 'Top 5 Risk Recommendations' ? (
                <Grid
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    width: '100%',
                  }}>
                  {Array.from(new Array(4)).map(i => (
                    <Grid
                      key={i}
                      sx={{
                        // border: '1px solid #cbd5e0',
                        borderRadius: '5px',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <Box width="100%" display="flex">
                        <Skeleton variant="rectangular" width="100%" height="80px" sx={{ borderRadius: '8px' }} />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              ) : title === 'Internet Exposed Resources' ? (
                <>
                  <Grid
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '15px',
                      width: '100%',

                      // pt: '15px',
                    }}>
                    {Array.from(new Array(5)).map(i => (
                      <Grid
                        key={i}
                        sx={{
                          padding: '0px 15px',
                          borderRadius: '5px',
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                        }}>
                        <Grid>
                          <Box width="100%" display="flex" flexDirection="row" justifyContent="flex-end">
                            <Skeleton variant="circular" width="30px" height="30px" />
                          </Box>
                        </Grid>
                        <Grid sx={{ width: '100%', marginLeft: '10px' }}>
                          <Box width="100%" display="flex" flexDirection="row" justifyContent="space-between">
                            <Skeleton width="100px" />
                            <Skeleton width="30px" />
                          </Box>
                          <Box width="100%">
                            <Skeleton width="100%" />
                          </Box>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </>
              ) : (
                <Box display="flex" flexDirection="column" alignItems="center" height="100%" width="100%">
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    height="170px"
                    paddingLeft="12px"
                    marginRight={'12px'}>
                    <Skeleton variant="circular" width="160px" height="160px" />
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    paddingRight="8px"
                    width="100%"
                    paddingBottom="15px"
                    borderRadius="5px"
                    gap={'10px'}
                    component="div">
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent={'space-between'}
                      gap={'10px'}
                      height={'76px'}
                      width="100%">
                      <Skeleton width="100%" height="120px" />
                      <Skeleton width="100%" height="120px" />
                    </Box>

                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent={'space-between'}
                      gap={'10px'}
                      // padding={'15px'}
                      height={'76px'}
                      width="100%">
                      <Skeleton width="100%" height="120px" />
                      <Skeleton width="100%" height="120px" />
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </ContentBox>
        ))}
      </Box>
    </PageContent>
  );
}

export default DashboardSkeleton;
