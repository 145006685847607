import React, { useState, useEffect, useRef, cloneElement } from 'react';
import { Tooltip as MuiTooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

const LightTooltip = styled(({ className, ...props }) => <MuiTooltip {...props} classes={{ popper: className }} />, {
  shouldForwardProp: prop => !['color', 'fontcolor', 'padding'].includes(prop),
})(({ theme, color, fontcolor, padding }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: color || '#fafafa',
    color: fontcolor || '#121212',
    boxShadow: theme.shadows[2],
    fontSize: 12,
    fontWeight: 500,
    fontFamily: 'Manrope',
    padding: padding || '4px 8px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: color || '#fafafa', // Arrow matches tooltip background
  },
}));

function Tooltip({
  title,
  children,
  color,
  isIcon = false,
  fromScan = false,
  fromDate = false,
  fontcolor,
  padding,
  ...props
}) {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const childRef = useRef();

  useEffect(() => {
    const checkOverflow = () => {
      if (childRef.current) {
        const { scrollWidth, clientWidth } = childRef.current;
        setIsOverflowing(scrollWidth > clientWidth);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [children]);

  const childWithRef = React.isValidElement(children) ? cloneElement(children, { ref: childRef }) : children;

  if (!title || !children) {
    return <>{children || null}</>; // Fallback for missing children
  }

  // console.log('isOverflowing', isOverflowing);

  return (
    <LightTooltip
      title={isOverflowing || isIcon || fromScan || fromDate ? title : ''}
      PopperProps={{
        sx: { zIndex: theme => theme.zIndex.tooltip }, // Reliable z-index
      }}
      arrow
      color={color}
      fontcolor={fontcolor}
      padding={padding}
      {...props}>
      {childWithRef}
    </LightTooltip>
  );
}

export default Tooltip;
