import { Box, Skeleton } from '@mui/material';

import TableSkeleton from '@root/components/table/skeleton';
import PageContent from '@root/components/v2/layout/PageContent';
import PageHeaderSkeleton from '@root/components/v2/layout/PageHeader/Skeleton';

function RulesTableSkleton() {
  return (
    <PageContent>
      <PageHeaderSkeleton isRegionFilter={true} />
      <Skeleton height="30px" width={'300px'} variant="rectangular" sx={{ borderRadius: '15px' }} />
      <Box className="Borders RoundedCorners" marginBottom="15px" marginTop="15px">
        <Skeleton height="30px" style={{ transform: 'none' }} />
      </Box>

      <TableSkeleton />
    </PageContent>
  );
}

export default RulesTableSkleton;
