import React from 'react';
import MuiTable from '@components/table/table';
import { Box, Skeleton } from '@mui/material';

const renderCell = () => {
  return (
    <Box width="200px">
      <Skeleton width="100%" height="40px" />
    </Box>
  );
};

const renderHeader = renderCell;

function TableSkeleton({
  paddingLeft,
  paddingRight,
  marginBottom = 0,
  COLUMNS_COUNT = 10,
  ROWS_COUNT = 10,
  hideFooter = true,
  noBorder = false,
  height = '63.7vh',
  width = 200,
}) {
  const columns = Array.from(new Array(COLUMNS_COUNT)).map(() => ({ renderHeader, renderCell, width: width }));
  const rows = Array.from(new Array(ROWS_COUNT)).map((i, index) => ({ id: index }));
  return (
    <Box
      height={height}
      paddingLeft={paddingLeft || 0}
      paddingRight={paddingRight || 0}
      marginBottom={marginBottom || 0}
      paddingBottom="15px">
      <MuiTable
        columns={columns}
        rows={rows}
        pagination={false}
        hideFooter={hideFooter}
        noBorder={noBorder}
        rowHeight={49.9}
      />
    </Box>
  );
}

export default TableSkeleton;
