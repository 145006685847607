import { Box, Skeleton } from '@mui/material';

function CloudProviderBarSkeleton({ marginBottom = '15px' }) {
  return (
    <Box
      className="Borders RoundedCorners"
      display="flex"
      flexDirection="row"
      justifyContent={'space-evenly'}
      alignItems="center"
      gap="10px"
      height="32px"
      marginBottom={marginBottom}
      backgroundColor="white"
      paddingLeft="10px"
      width={'425px'}
      paddingRight="10px">
      <Skeleton width="80px" />
      <Skeleton width="80px" />
      <Skeleton width="80px" />
      <Skeleton width="80px" />
    </Box>
  );
}

export default CloudProviderBarSkeleton;
