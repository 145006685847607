import React from 'react';
import { Box, Skeleton } from '@mui/material';

import CloudProviderBarSkeleton from '@root/components/v2/content/CloudProviderBar/skeleton';
import PageContent from '@root/components/v2/layout/PageContent';
import ContentBox from '@root/components/v2/content/ContentBox';
import PageHeaderSkeleton from '@root/components/v2/layout/PageHeader/Skeleton';
const ResourceBarSkeleton = () => {
  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      padding={'15px 0px'}
      paddingTop={'5px'}
      alignItems={'center'}>
      <Box display={'flex'} alignItems={'center'} gap={'15px'}>
        <Skeleton variant="rectangular" height={'20px'} width={'200px'} sx={{ borderRadius: '4px' }} />
        <Skeleton variant="rectangular" height={'20px'} width={'200px'} sx={{ borderRadius: '4px' }} />
        <Skeleton variant="rectangular" height={'28px'} width={'400px'} sx={{ borderRadius: '4px' }} />
      </Box>
      <Box display={'flex'} alignItems={'center'} gap={'25px'}>
        <Skeleton variant="rectangular" height={'25px'} width={'25px'} sx={{ borderRadius: '4px' }} />
        <Skeleton variant="rectangular" height={'25px'} width={'25px'} sx={{ borderRadius: '4px' }} />
        <Skeleton variant="rectangular" height={'25px'} width={'25px'} sx={{ borderRadius: '4px' }} />
      </Box>
    </Box>
  );
};
function ResourceSkeleton() {
  return (
    <PageContent>
      <PageHeaderSkeleton isRegionFilter={true} />
      <CloudProviderBarSkeleton marginBottom="5px" />
      <ResourceBarSkeleton />
      <ContentBox paddingMode="content" marginBottom="15px" paddingTop="15px">
        <ContentBox
          paddingMode="content"
          marginRight="15px"
          marginLeft="15px"
          title={<Skeleton width="70px" height="20px" />}
          headerEndElement={<Skeleton width="70px" />}>
          <Box display="flex" gap="15px" padding="15px">
            {Array.from(new Array(5)).map((title, index) => (
              <ContentBox key={index} containerClassName={'border-box'} height={'114px'} width="100%">
                <Box width="100%" display="flex">
                  <Box
                    height="100%"
                    sx={{
                      display: 'flex',
                      gap: '1.5rem',
                      alignItems: 'center',
                      flexGrow: 1,
                    }}>
                    <Box
                      sx={{
                        marginBottom: '.5rem',
                      }}>
                      <Skeleton height="48px" width="48px" variant="circular" style={{ transform: 'none' }} />
                    </Box>
                    <Box width="100%">
                      <Box width="100%" marginBottom="10px">
                        <Skeleton width="100px" />
                      </Box>
                      <Box width="100%" marginBottom="15px">
                        <Skeleton width="100px" />
                      </Box>
                      <Box width="100%">
                        <Skeleton width="100px" />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </ContentBox>
            ))}
          </Box>
        </ContentBox>
        {Array.from(new Array(10)).map(() => (
          <Box
            className="Borders RoundedCorners"
            marginLeft="15px"
            marginRight="15px"
            marginBottom="15px"
            marginTop="15px">
            <Skeleton height="40px" style={{ transform: 'none' }} />
          </Box>
        ))}
      </ContentBox>
    </PageContent>
  );
}

export default ResourceSkeleton;
