import { Box } from '@mui/material';
import { axiosHelper } from '@root/services/axios';
import { useMutation } from '@tanstack/react-query';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import './tenatswitch.scss';
// import AppContext from '../../context/Context';
import agent from '@root/agent';
import profile from '@root/assets/svg/icons/new/identity/Profile.svg';
import { SET_TENANTS } from '@root/constants/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import SubTenatSwitchingErrorModal from './TenantSwitchingError';
import SideOverlay from '@root/components/common/SideOverlayNew';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import users_selected from '@root/assets/svg/icons/new/selected/Users.svg';
import './TopNavbar.scss';
// import { Tooltip } from '@mui/material';
import {
  ChangePasswordIcon,
  HelpIcon,
  LogoutIcon,
  // SettingsIcon,
  SwitchTenanticon,
} from '@root/assets/svg/SVGComponents';
import Tooltip from '@root/components/v2/ui-elements/Tooltip';
import TenantSwitchingNew from './TenantSwitchingNew';
import Profile from '@root/pages/profile';

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

const ProfileDropdown = ({ user }) => {
  const dispatch = useDispatch();
  const [tenantList, setTenantlist] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [delayed, setDelayed] = useState(false);
  const [currentTenant, setCurrentTenant] = useState([]);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const [templist, setTempList] = useState([]);
  const [openSideOverlay, setOpenSideOverlay] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [checked, setChecked] = useState(true);
  const [value, setValue] = useState('');
  const [tempTenant, setTempTenant] = useState([]);
  const [errormodalShow, setErrorModalShow] = useState(false);
  const is_org_owner = useSelector(state => state.user.info.user.is_org_owner);
  const { email, id, organization } = useSelector(state => state.user.info.user);

  useEffect(() => {
    if (!_.isEmpty(tenantList)) {
      if (localStorage.getItem('tenantID')) {
        setCurrentTenant(localStorage.getItem('tenantName'));
      }
    }
  }, [tenantList]);
  useEffect(() => {
    if (!_.isEmpty(user['info'])) {
      axiosHelper.axiosGet('user_subtenant_list', true).then(response => {
        if (response?.['data']) {
          setTenantlist(response['data']);
          setTempList(response['data']);
          setCurrentTenant(localStorage.getItem('tenantName'));

          dispatch({ type: SET_TENANTS, payload: response.data });
        }
      });
    }
  }, [user, dispatch]);
  const onSearch = event => {
    // TODO: Future Bug. Searching will reset the selected values most probably.
    // let original = groupsnusers
    let query = event.target.value;
    if (query !== '') {
      setValue(query);
      let newArray = tenantList.filter(obj =>
        JSON.stringify(obj['tenant_name']).toLowerCase().includes(query?.toLowerCase()),
      );
      if (_.isEmpty(newArray)) {
        setChecked(true);
      }
      setTempList(newArray);
    } else {
      setTempTenant([]);
      setTempList(tenantList);
      setChecked(true);
      setValue('');
    }
  };

  const onTenantSelect = element => {
    if (!tempTenant.includes(element)) {
      setTempTenant([element]);
      setChecked(false);
    }
  };
  const setTenant = () => {
    if (!_.isEmpty(tempTenant)) {
      let tenant = tenantList.filter(e => e.tenant_name === tempTenant[0]['tenant_name']);
      localStorage.setItem('tenantID', tenant[0]['id']);
      localStorage.setItem('tenantName', tenant[0]['tenant_name']);
      localStorage.removeItem('User Policy');
      localStorage.removeItem('User Policy Table');
      setCurrentTenant(tenant);
      setModalShow(false);
      window.location.href = '/';
    } else {
      toast.error('Please Select at least one tenant');
    }
  };

  const handleLogout = async () => {
    localStorage.removeItem('tenantName');
    await agent.Auth.logout({ email, user_id: id, tenant_id: organization, refresh_token: localStorage.getItem('_r') });
  };

  const handleOpenSideOverlay = () => {
    setOpenSideOverlay(true);
  };

  const getHelpMutation = useMutation({
    mutationFn: async () => {
      const response = await agent.ClientDashboard.get_help();
      const url = response?.data?.signedUrl;

      if (!url) throw new Error('No help url found.');

      window.open(url, '_blank');
    },
    onError: async e => {
      toast.error(`Failed to open Help: ${e?.message || ''}`);
    },
  });

  const handleHelpClick = useCallback(() => {
    getHelpMutation.mutate();
  }, [getHelpMutation]);

  useEffect(() => {
    if (!dropdownOpen) {
      setTempTenant([]);
      setChecked(true);
      setTempList(tenantList);
    }

    const timeout = setTimeout(() => {
      setDelayed(dropdownOpen);
    }, 240);

    return () => clearTimeout(timeout);
  }, [dropdownOpen, tenantList]);

  return (
    <>
      <SubTenatSwitchingErrorModal
        show={errormodalShow}
        onHide={() => {
          setErrorModalShow(false);
        }}
      />
      <Dropdown nav inNavbar isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle nav>
          <div className="TopNavbar__Profile--inner" onClick={() => setValue('')}>
            <div className="Profile__Avatar">
              {/* <Avatar src={team3} imgClassname="Profile__Avatar--img" />
              <div className=''> */}
              {user.info.user.email.charAt(0).toUpperCase()}
              {/* </div> */}
            </div>
            <div>
              {!_.isEmpty(tenantList) && (
                <>
                  {/* <Tooltip title={`${currentTenant?.length > 2 ? currentTenant : tenantList[0]['tenant_name']}`}> */}
                  {/* <div className="Profile__Name">Hi, {user.info.user.name}</div> */}
                  {/* </Tooltip> */}
                  <div className="Profile__Mail">{user.info.user.email}</div>
                  <div className="Profile__Mail" style={{ fontSize: '10px' }}>
                    {currentTenant?.length > 2 ? currentTenant : tenantList[0]['tenant_name']}
                  </div>
                </>
              )}
            </div>
            <div className={`Profile__DropdownArrow`}>
              <KeyboardArrowDownIcon
                className={`Profile__Arrow ${dropdownOpen ? 'Profile__Arrow--expanded' : ''}`}
                sx={{ color: '#fff' }}
              />
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                >
                <circle cx="9" cy="9" r="8.75" fill="#CBD5E0" stroke="#718096" stroke-width="0.5" />
                <path
                  d="M6 8L9 11L12 8"
                  stroke="#1A202C"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg> */}
            </div>
          </div>
        </DropdownToggle>

        <DropdownMenu
          bottom
          className="ProfileDropdown__Container"
          style={{ boxShadow: delayed ? '0px 8px 18px 0px rgba(93, 106, 131, 0.10)' : 'none' }}>
          <div className="ProfileDropdown__Menu">
            <Box
              // onClick={() => (is_org_owner ? setModalShow(true) : setErrorModalShow(true))}
              // component={Link}

              className=" ProfileDropdown__Item_header"
              style={{ height: '49px', borderBottom: '1px solid #cbd5e0', borderLeft: '0px' }}>
              <Box>
                <Box>
                  {!_.isEmpty(tenantList) && (
                    <Box display="flex" alignItems="center">
                      <div className="avatar-in-dropdwon">{user.info.user.email.charAt(0).toUpperCase()}</div>
                      <Box marginLeft="10px">
                        <div className="Profile__Name" style={{ textTransform: 'capitalize' }}>
                          {' '}
                          {user.info.user.name}
                        </div>
                        <div className="Profile__Mail" style={{ fontSize: '10px' }}>
                          {currentTenant?.length > 2 ? currentTenant : tenantList[0]['tenant_name']}
                        </div>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            <Box
              // onClick={() => (is_org_owner ? setModalShow(true) : setErrorModalShow(true))}
              // component={Link}
              className="ProfileDropdown__Item ProfileDropdown__Item-1">
              {is_org_owner && tenantList.length !== 0 && (
                <TenantSwitchingNew
                  show={modalShow}
                  onHide={() => {
                    setModalShow(false);
                    setTempTenant([]);
                    setChecked(true);
                  }}
                  templist={templist.filter(
                    tenant =>
                      tenant.tenant_name !== (currentTenant?.length > 2 ? currentTenant : tenantList[0]['tenant_name']),
                  )}
                  setTenant={setTenant}
                  onTenantSelect={onTenantSelect}
                  onSearch={onSearch}
                  tempTenant={tempTenant}
                  checked={checked}
                  value={value}
                />
              )}
              <Tooltip title={tenantList.length === 0 ? 'No other tenants exist' : ''} placement="left">
                <Box
                  onClick={() => {
                    is_org_owner ? setModalShow(true) : setErrorModalShow(true);
                  }}
                  // component={Link}
                  // className="ProfileDropdown__Item ProfileDropdown__Item-1"
                  sx={{ display: 'flex', gap: '10px' }}>
                  <SwitchTenanticon />
                  <span> Switch tenants</span>
                </Box>
              </Tooltip>
            </Box>

            <Box
              component={Link}
              to="/profile/change-password"
              onClick={toggle}
              className="ProfileDropdown__Item ProfileDropdown__Item-2">
              <ChangePasswordIcon />
              Change Password
            </Box>
            <Box
              component={Link}
              // to="/profile"
              onClick={handleOpenSideOverlay}
              className="ProfileDropdown__Item ProfileDropdown__Item-3">
              <img src={profile} style={{ color: '#1a202c !important', fill: 'rgb(72, 77, 86) !important' }} />
              Profile
            </Box>
            <Box
              onClick={handleHelpClick}
              component={Link}
              // onClick={toggle}
              //to="#"
              className="ProfileDropdown__Item ProfileDropdown__Item-4">
              <HelpIcon />
              Help
            </Box>
            <Box className=" ProfileDropdown__Item-5" />
            <Box
              onClick={() => {
                handleLogout();
                toggle();
              }}
              component={Link}
              to="/logout"
              className="ProfileDropdown__Item ProfileDropdown__Item-6">
              <LogoutIcon />
              Logout
            </Box>
          </div>
        </DropdownMenu>
      </Dropdown>
      <SideOverlay open={openSideOverlay} onClose={() => setOpenSideOverlay(false)} width={'50%'}>
        <Profile />
      </SideOverlay>
    </>
  );
};

// export default ProfileDropdown;
export default connect(mapStateToProps)(ProfileDropdown);
