import { LOCK_MENU_ITEMS, SET_USER, UNLOCK_MENU_ITEMS } from '@root/constants/actionTypes';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import _ from 'lodash';
import React, { Suspense, useCallback, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import io from 'socket.io-client';
import './_lib.scss';
import agent from './agent';
import { PrivateRoute, PublicRoute } from './routes/_routeHOC';
import AppRoutes from './routes/appRoutes';
import OnBoardingRoutes from './routes/navRoutes/onboardingRoutes';
// import orgOwnerRegistration from '@root/containers/auth/orgOwnerRegistration';

const browserHistory = createBrowserHistory();
const IndividualCompliance = React.lazy(() => import('@root/containers/compliance/components/complianceChip/index'));
const Login = React.lazy(() => import('@root/pages/auth/Login'));
const LoginNew = React.lazy(() => import('@root/pages/auth/LoginNew'));
const GcpRegistration = React.lazy(() => import('@root/pages/gcp-registration'));
const AwsRegistration = React.lazy(() => import('@root/pages/aws-registration'));
const Logout = React.lazy(() => import('@root/pages/auth/Logout'));
const ForgotPassword = React.lazy(() => import('@root/pages/auth/Forgot-Password'));
const RegistrationForm = React.lazy(() => import('@root/pages/auth/Registration'));
const orgOwnerRegistration = React.lazy(() => import('@root/pages/auth/orgOwnerRegistration'));
// const SetPassword = React.lazy(() => import('@root/pages/auth/Forgot-Password/SetPasswordForm'));
import { fetchuserPolicyTableData } from '@containers/dashboard/action';
import RemediationHandler from './RemediationHandler';
const AwsRegistrationError = React.lazy(() => import('@root/pages/error-aws-handle'));

import AppLogoLoader from './components/AppLogoLoader';
import './styles/index.scss';

import environments from './hooks/environments';
import usePubSub from './hooks/usePubSub';
import { updateNotificationBanner } from './components/notification/actions';

const mapStateToProps = state => {
  return {
    user: state.user,
    isLoggedIn: state.user.isLoggedIn,
    isMenuLocked: state.onboarding.isMenuLocked,
  };
};

const mapDispatchToProps = dispatch => ({
  setUser: payload => dispatch({ type: SET_USER, payload }),
  lockMenu: () => dispatch({ type: LOCK_MENU_ITEMS }),
  unlockMenu: () => dispatch({ type: UNLOCK_MENU_ITEMS }),
  fetchUserPolicyTable: () => dispatch(fetchuserPolicyTableData),
});

const App = props => {
  const dispatch = useDispatch();
  const { user, isMenuLocked } = props; // details about user
  const { setUser, lockMenu, unlockMenu } = props; // redux hooks
  const [isAppLoading, setAppLoading] = React.useState(true);
  const token = window.localStorage.getItem('_t');
  const rToken = window.localStorage.getItem('_r');
  const subtenantID = window.localStorage.getItem('tenantID');
  const { publish } = usePubSub();
  const history = useHistory();

  const LockPortalMethod = useCallback(() => {
    if (!_.isEmpty(user['info'])) {
      const { account_added, is_super } = user['info']['user'];
      switch (true) {
        case is_super && !account_added:
          lockMenu();
          break;
        case account_added:
          unlockMenu();
          break;
        default:
          break;
      }
    }
  }, [user, lockMenu, unlockMenu]);

  React.useEffect(() => {
    (async function () {
      if (!_.isEmpty(token) && !_.isEmpty(rToken)) {
        const userRes = await agent.Auth.current(subtenantID);
        setUser(userRes);
        environments.setToken(token);
      }
      setAppLoading(false);
    })();
  }, [rToken, setUser, subtenantID, token]);

  React.useEffect(() => {
    LockPortalMethod();
  }, [LockPortalMethod, user]);
  useEffect(() => {
    const newSocket = io(`${import.meta.env.VITE_WEBSOCKET_NOTIFICATION}?channel=${subtenantID}`, {
      transports: ['websocket'], // Use WebSocket transport only to avoid long-polling
    });
    newSocket.on('connect', () => {
      console.log('Notification socket connected!');
    });
    newSocket.on('alert', msg => {
      if (msg.channel === subtenantID) {
        const parsedMsg = msg.message;
        console.log(parsedMsg, 'bjdbnfmdjng');
        if (parsedMsg && parsedMsg['event_type'] === 'RESOURCE_TAG') {
          publish('alerts', parsedMsg);
        } else if (parsedMsg && parsedMsg['event_type'] === 'alerts') {
          publish('notification', parsedMsg);
        } else if (parsedMsg && parsedMsg['event_type'] === 'SCAN_HISTORY') {
          publish('scan_history', parsedMsg);
        } else if (parsedMsg && parsedMsg['event_type'] === 'ONE_CLICK_REMEDIATION') {
          publish('one_click_remediation', parsedMsg);

          if (parsedMsg && parsedMsg?.completed !== parsedMsg?.total) {
            updateNotificationBanner(dispatch, {
              status: 'inProgress',
              msg: `Remediation in progress for ${parsedMsg?.completed}/${parsedMsg?.total} resources`,
              id: 'bulk_remediation_rules',
            });
          } else if (parsedMsg && parsedMsg?.completed === parsedMsg?.total) {
            if (parsedMsg?.success_count === 0) {
              updateNotificationBanner(dispatch, {
                status: 'error',
                htmlMsg: (
                  <span>
                    Remediation failed for {parsedMsg?.completed}/{parsedMsg?.total} Resource(s).
                    <a
                      style={{ textDecoration: 'none', color: 'blue', cursor: 'pointer' }}
                      href={'/remediation-report'}>
                      Click
                    </a>{' '}
                    to see the report{' '}
                  </span>
                ),
                id: 'bulk_remediation_rules',
              });
            } else {
              updateNotificationBanner(dispatch, {
                status: 'success',
                htmlMsg: (
                  <span>
                    Remediation completed successfully for {parsedMsg?.success_count}/{parsedMsg?.total} Resource(s).
                    <a
                      style={{ textDecoration: 'none', color: 'blue', cursor: 'pointer' }}
                      href={'/remediation-report'}>
                      Click
                    </a>{' '}
                    to see the report{' '}
                  </span>
                ),
                id: 'bulk_remediation_rules',
              });
            }
          }
        }

        if (parsedMsg && parsedMsg['event_type'] === 'Download Report') {
          console.log(parsedMsg, 'parsedMsg');
          publish('download_Report', parsedMsg);
          console.log('download_Report');
        }
      }
    });
    newSocket.on('disconnect', reason => {
      console.log('Socket disconnected:', reason);
      if (reason === 'io server disconnect') {
        // The disconnection was initiated by the server, you need to reconnect manually
        newSocket.connect();
      }
    });
    newSocket.on('connect_error', error => {
      console.error('Connection error:', error);
    });

    return () => newSocket.close();
  }, [dispatch, history, publish, subtenantID]);

  // const _appRoutes=  useMemo(() => {
  //   if (isMenuLocked) return <OnBoardingRoutes />;
  //   return (
  //     // <WebSocketProvider>
  //     <AppRoutes />
  //     // </WebSocketProvider>
  //   );
  // ,[])
  return (
    <>
      {isAppLoading && <AppLogoLoader />}
      {!isAppLoading && (
        <Suspense fallback={<AppLogoLoader />}>
          <ConnectedRouter history={browserHistory}>
            <Switch>
              <PublicRoute path="/development" exact component={IndividualCompliance} />
              <PublicRoute path="/login/old" exact component={Login} />
              <PublicRoute path="/login" exact component={LoginNew} />
              <PrivateRoute path="/logout" component={Logout} />
              <PublicRoute path="/forgot-password" exact component={ForgotPassword} />
              <PublicRoute path="/set-password" exact component={ForgotPassword} />
              <PublicRoute path="/user_invite/:subtenant_id/:query__int" exact component={RegistrationForm} />
              <PublicRoute path="/org_user_invite/:user_id/:query__int" exact component={orgOwnerRegistration} />
              <Route path="/gcp/register" exact component={GcpRegistration} />
              <Route path="/aws/register" exact component={AwsRegistration} />
              <Route path="/aws" exact component={AwsRegistrationError} />

              <PrivateRoute
                component={() => {
                  if (isMenuLocked) return <OnBoardingRoutes />;
                  return (
                    // <WebSocketProvider>
                    <AppRoutes />
                    // </WebSocketProvider>
                  );
                }}
              />
            </Switch>
          </ConnectedRouter>
        </Suspense>
      )}
      <ToastContainer />

      <RemediationHandler />
    </>
  );
};

const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App);
export default ConnectedApp;
