import React from 'react';
import Flex from './Flex';
import './loader.scss';
const Loader = props => (
  <Flex style={{ height: props.height || '100%', justifyContent: 'center', alignItems: 'center' }}>
    <div className="loader default default-01">
      <div className="loader-container">
        <svg width="80" height="80" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.964 11.253 0 7.467 11.82.566a4.18 4.18 0 0 1 4.211.006L26.05 6.46C27.26 7.173 28 8.444 28 9.815v10.68c0 1.397-.767 2.688-2.015 3.392l-9.894 5.578a4.18 4.18 0 0 1-4.05.024L0 22.891V15.18l13.928 7.712 6.963-3.926v-7.712l-6.963-3.786z"
            fill="url(#a)"
          />
          <path d="M6.969 19.034v-7.781l6.963-3.786 6.964 3.786v7.711l-6.964 3.927z" fill="#fff" />
          <path d="m6.969 11.254 6.963 3.926v7.712L6.97 19.03z" fill="#CBD5E0" />
          <path d="m13.933 15.179 6.963-3.926-6.964-3.786-6.963 3.786z" fill="url(#b)" />
          <defs>
            <linearGradient id="a" x1="0" y1="0" x2="0" y2="30" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FC00FF" />
              <stop offset="1" stop-color="#00DBDE" />
            </linearGradient>
            <linearGradient id="b" x1="6.839" y1="10.221" x2="8.616" y2="10.221" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FC00FF" />
              <stop offset="0" stop-color="#00B2FF" />
              <stop offset="1" stop-color="#00DBDE" />
            </linearGradient>
          </defs>
        </svg>
        <div class="loader-aniamted" style={{ marginTop: '14px', marginLeft: '9px' }}></div>
      </div>
    </div>
  </Flex>
);

export default Loader;

/* HTML: <div class="loader"></div> */
