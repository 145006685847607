import { Box, Grid, Skeleton } from '@mui/material';

import CloudProviderBarSkeleton from '@root/components/v2/content/CloudProviderBar/skeleton';
import PageContent from '@root/components/v2/layout/PageContent';
import ContentBox from '@root/components/v2/content/ContentBox';
import PageHeaderSkeleton from '@root/components/v2/layout/PageHeader/Skeleton';
import './style.scss';
import TableSkeleton from '@root/components/table/skeleton';

const TitleTab = () => {
  return (
    <div className="KeyInsightsTitleTab__Container" style={{ paddingLeft: '0px' }}>
      <div style={{ height: '100%' }} className={`KeyInsightsTitleTab`}>
        Serverless Function
      </div>
      <div style={{ height: '100%' }} className={`KeyInsightsTitleTab`}>
        Cloud Storage
      </div>
    </div>
  );
};
function KeyInsightSkeleton() {
  return (
    <PageContent className="PageContent__Skeleton_pt">
      <PageHeaderSkeleton />
      <CloudProviderBarSkeleton marginBottom="5px" />
      <>
        <TitleTab />
        <Box display={'flex'} flexDirection={'column'} gap={'15px'}>
          <Box display="flex" flexDirection="row" gap="15px" paddingTop={'15px'}>
            {['Risk', 'Triggers', 'Resource', 'Runtime Vulnerabilities'].map(title => (
              <ContentBox
                key={title}
                title={title}
                height={'396px'}
                paddingMode="content"
                headerEndElement={<Skeleton width="40px" />}
                style={{ flexGrow: 1, width: 'calc(25% - 15px)' }}>
                {/* Set a fixed width for each ContentBox */}

                <Box display="flex" flexDirection="column" alignItems="center" padding="15px" paddingTop="35px">
                  <Box width="100%" display="flex" justifyContent="center" marginBottom="5px">
                    {title === 'Top Service Categories' || title === 'Resource Trend' ? (
                      <Skeleton variant="rectangular" height="160px" sx={{ width: '100%', flexGrow: 1 }} />
                    ) : (
                      <Skeleton variant="circular" width="160px" height="160px" />
                    )}
                  </Box>
                  <Box flexGrow={0.4} height="10px" width="100%" />

                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    marginBottom="10px">
                    <Skeleton width="100px" />
                    <Skeleton width="100px" />
                  </Box>
                  <Grid sx={{ width: '100%' }}>
                    {Array.from(new Array(title === 'Cloud Provider' ? 3 : 4)).map((i, index) => (
                      <Box
                        key={i}
                        width="100%"
                        display="flex"
                        flexDirection="column"
                        gap={title === 'Cloud Provider' ? '15px' : '10px'}>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          marginTop={index === 0 ? '0px' : title === 'Cloud Provider' ? '20px' : '8px'}>
                          <Skeleton width="80px" />
                          {title === 'Resource Trend' && <Skeleton width="80px" />}
                          <Skeleton width="80px" />
                        </Box>
                      </Box>
                    ))}
                  </Grid>
                </Box>
              </ContentBox>
            ))}
          </Box>
          <Box display="flex" flexDirection="row" gap="15px">
            {['Evaluation', 'Runtimes', 'Exposed Secrets', 'Top 5 CVE'].map(title => (
              <ContentBox
                key={title}
                title={title}
                paddingMode="content"
                headerEndElement={<Skeleton width="40px" />}
                style={{ flexGrow: 1, width: 'calc(25% - 15px)' }}>
                {/* Set a fixed width for each ContentBox */}

                <Box display="flex" flexDirection="column" alignItems="center" padding="15px" paddingTop="35px">
                  <Box width="100%" display="flex" justifyContent="center" marginBottom="5px">
                    {title === 'Top Service Categories' || title === 'Resource Trend' ? (
                      <Skeleton variant="rectangular" height="160px" sx={{ width: '100%', flexGrow: 1 }} />
                    ) : (
                      <Skeleton variant="circular" width="160px" height="160px" />
                    )}
                  </Box>
                  <Box flexGrow={0.4} height="10px" width="100%" />

                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    marginBottom="10px">
                    <Skeleton width="100px" />
                    <Skeleton width="100px" />
                  </Box>
                  <Grid sx={{ width: '100%' }}>
                    {Array.from(new Array(title === 'Cloud Provider' ? 3 : 4)).map((i, index) => (
                      <Box
                        key={i}
                        width="100%"
                        display="flex"
                        flexDirection="column"
                        gap={title === 'Cloud Provider' ? '15px' : '10px'}>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          marginTop={index === 0 ? '0px' : title === 'Cloud Provider' ? '20px' : '8px'}>
                          <Skeleton width="80px" />
                          {title === 'Resource Trend' && <Skeleton width="80px" />}
                          <Skeleton width="80px" />
                        </Box>
                      </Box>
                    ))}
                  </Grid>
                </Box>
              </ContentBox>
            ))}
          </Box>
          <TableSkeleton ROWS_COUNT={9} COLUMNS_COUNT={9} />
        </Box>

        {/* <ContentBox
            titleSize="small"
            title="Serverless Functions"
            padding="0px"
            // subtitle="Top Non Compliance ensures legal adherence"
            titleContainerStyle={{
              // border: "0px",

              borderBottom: '1px solid #CBD5E0',
              padding: '11.04px 15px',
              margin: '0px',
            }}
            containerClassName={'border-box-no-pad'}
            marginBottom={'1.7rem'}
            paddingMode="content"
            // headerEndElement={
            //   <Box className="resources-tabbar-icons">
            //     <Description
            //       onClick={() => {
            //         if (darkMode) {
            //           document.getElementsByTagName('html')[0].dataset.theme = 'light';
            //         }
            //         else
            //           document.getElementsByTagName('html')[0].dataset.theme = 'dark';

            //         setDarkMode(!darkMode);

            //       }}
            //     />
            //   </Box>
            // }
            paddingMode="content"></ContentBox> */}
        {/* aws s3 */}
        {/* <ContentBox
            titleSize="medium"
            title="Cloud Storage"
            padding="0px"
            titleContainerStyle={{
              // border: "0px",

              borderBottom: '1px solid #CBD5E0',
              padding: '11.04px 15px',
              margin: '0px',
            }}
            // subtitle="Top Non Compliance ensures legal adherence"
            // headerEndElement={
            //   <Box className="resources-tabbar-icons">
            //     <Description
            //       onClick={() => {
            //         if (darkMode) {
            //           document.getElementsByTagName('html')[0].dataset.theme = 'light';
            //         }
            //         else
            //           document.getElementsByTagName('html')[0].dataset.theme = 'dark';

            //         setDarkMode(!darkMode);

            //       }}
            //     />
            //   </Box>
            // }
            paddingMode="content"></ContentBox> */}
      </>
    </PageContent>
  );
}

export default KeyInsightSkeleton;
