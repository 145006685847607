import { Box, Skeleton } from '@mui/material';
import TableSkeleton from '@root/components/table/skeleton';
// import { height } from '@mui/system';

import CloudProviderBarSkeleton from '@root/components/v2/content/CloudProviderBar/skeleton';
import ContentBox from '@root/components/v2/content/ContentBox';
import Column from '@root/components/v2/layout/columns/Column';
import ColumnsContainer from '@root/components/v2/layout/columns/ColumnsContainer';
import PageContent from '@root/components/v2/layout/PageContent';

function OverviewSkeleton() {
  return (
    // <PageContainer>
    //   <PageHeader title="Overview" />

    <PageContent>
      <Skeleton
        variant="rectangular"
        height="15px"
        width={'100px'}
        sx={{ marginBottom: '15px', borderRadius: '8px' }}
      />
      <CloudProviderBarSkeleton />

      <ContentBox
        title="CQ Shield Priority"
        paddingMode="content"
        marginBottom="15px"
        style={{ height: '130px' }}
        titleContainerStyle={{ padding: '0px 10px' }}>
        <Box padding="5px 15px" display={'flex'} flexDirection={'column'} justifyContent={'center'}>
          <ColumnsContainer>
            {Array.from(new Array(4)).map(i => (
              <Column key={i}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems={'center'}
                  //
                  height="80px">
                  <Skeleton component="div" width="40px" height="40px" style={{ transform: 'none' }}></Skeleton>
                  <Box display="flex" flexDirection="column" flexGrow={1} justifyContent="center" paddingLeft="10px">
                    <Skeleton width="40px"></Skeleton>
                    <Skeleton></Skeleton>
                  </Box>
                </Box>
              </Column>
            ))}
          </ColumnsContainer>
        </Box>
      </ContentBox>

      <ColumnsContainer marginBottom="15px">
        <Column>
          <ContentBox
            title="Identity Risk"
            paddingMode="content"
            titleContainerStyle={{ padding: '0px 10px' }}
            style={{ height: '410px' }}>
            <Box padding="30px" paddingTop={'15px'}>
              {Array.from(new Array(5)).map((i, index) => (
                <Box height="50px" marginBottom={index === 4 ? 0 : '15px'}>
                  <Box key={i} display="flex" flexDirection="row" justifyContent="space-between">
                    <Skeleton width="100px" />
                    <Skeleton width="70px" />
                  </Box>
                  <Skeleton />
                </Box>
              ))}
            </Box>
          </ContentBox>
        </Column>
        <Column>
          <ContentBox
            title="Top 5 IAM Risk"
            paddingMode="content"
            titleContainerStyle={{ padding: '0px 10px' }}
            style={{ height: '410px' }}>
            <Box padding="30px" paddingTop={'15px'}>
              {Array.from(new Array(5)).map((i, index) => (
                <Box
                  key={i}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  gap="10px"
                  height="50px"
                  marginBottom={index === 4 ? 0 : '15px'}>
                  <Skeleton width="30px" height="30px" style={{ transform: 'none' }} />
                  <Skeleton style={{ flexGrow: 1 }} />
                  <Skeleton width="30px" height="30px" style={{ transform: 'none' }} />
                </Box>
              ))}
            </Box>
          </ContentBox>
        </Column>
        <Column>
          <ContentBox
            title="IAM Compliance Score"
            paddingMode="content"
            titleContainerStyle={{ padding: '0px 10px' }}
            style={{ height: '410px' }}>
            <Box padding="30px" paddingTop={'15px'}>
              {Array.from(new Array(5)).map((i, index) => (
                <Box
                  key={i}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  gap="10px"
                  height="50px"
                  marginBottom={index === 4 ? 0 : '15px'}>
                  <Skeleton width="30px" height="30px" style={{ transform: 'none' }} />
                  <Box flexGrow={1}>
                    <Skeleton />
                    <Skeleton />
                  </Box>
                </Box>
              ))}
            </Box>
          </ContentBox>
        </Column>
      </ColumnsContainer>

      <Box display={'flex'} width={'100%'} gap={'15px'}>
        <ContentBox title="IAM Asset Types" paddingMode="content" style={{ flexGrow: 1 }}>
          <Box display="flex" flexDirection="row" padding="15px" gap="15px">
            {Array.from(new Array(5)).map(i => (
              <Box
                key={i}
                //
                display="flex"
                flexDirection="column"
                alignItems="center"
                flexGrow={1}
                padding="10px">
                <Skeleton width="100px" height="100px" variant="circular" />

                <Box display="flex" flexDirection="row" alignItems="center" width="100%" marginTop="15px" gap="10px">
                  {Array.from(new Array(2)).map(i => (
                    <Box key={i} display="flex" flexDirection="row" flexGrow={1}>
                      <Box
                        width="8px"
                        height="8px"
                        borderRadius="50%"
                        backgroundColor="#ddd"
                        marginTop="10px"
                        marginRight="10px"
                      />
                      <Box flexGrow={1}>
                        <Skeleton width="20px" />
                        <Skeleton />
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        </ContentBox>
        <ContentBox title="Over Privileged Identities" paddingMode="content" width="300px">
          <Box display="flex" flexDirection="column" alignItems="center" flexGrow={1} padding="30px">
            <Skeleton width="100px" height="100px" variant="circular" />

            <Box display="flex" flexDirection="row" alignItems="center" width="100%" marginTop="15px" gap="10px">
              {Array.from(new Array(3)).map(i => (
                <Box key={i} display="flex" flexDirection="row" flexGrow={1}>
                  <Box
                    width="8px"
                    height="8px"
                    borderRadius="50%"
                    backgroundColor="#ddd"
                    marginTop="10px"
                    marginRight="10px"
                  />
                  <Box flexGrow={1}>
                    <Skeleton width="20px" />
                    <Skeleton />
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </ContentBox>
      </Box>
      <Box display={'flex'} width={'100%'} gap={'15px'} style={{ marginTop: '15px' }}>
        <ContentBox title="Anomaly/Threats" paddingMode="content" width="33.333%">
          <>
            <TableSkeleton ROWS_COUNT={4} COLUMNS_COUNT={2} height="300px" width={300} />
          </>
        </ContentBox>
        <ContentBox title="Attack Paths" paddingMode="content" width="33.333%">
          <>
            <TableSkeleton ROWS_COUNT={4} COLUMNS_COUNT={2} height="300px" width={300} />
          </>
        </ContentBox>
        <ContentBox title="Top Risky Identities" paddingMode="content" width="33.333%">
          <>
            <TableSkeleton ROWS_COUNT={4} COLUMNS_COUNT={2} height="300px" width={300} />
          </>
        </ContentBox>
      </Box>
    </PageContent>
    // </PageContainer>
  );
}

export default OverviewSkeleton;
